"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

var _ResourceAttributeTag = _interopRequireDefault(require("@/components/ResourceAttributeTag"));

var _SyncInfo = _interopRequireDefault(require("@/components/Resource/Selector/SyncInfo.vue"));

var _extent = require("../../../../utils/time/extent");

var _CategoryView = _interopRequireDefault(require("@/components/Resource/Selector/CategoryView.vue"));

var _AreaOption = _interopRequireDefault(require("@/components/Resource/Selector/AreaOption.vue"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * 产生一个 el-date-picker 快捷方式的点击事件
 *
 * @param {number} days
 * @returns {Function}
 */
function pickerShortcutsOnClickBuilder() {
  var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return function (picker) {
    var start = new Date();
    start.setTime(start.getTime() - days * _extent.oneDay);
    picker.$emit('pick', [start, new Date()]);
  };
}

var _default2 = {
  name: 'ColumnDialogFilterPanel',
  components: {
    SelectParamSyncInfo: _SyncInfo.default,
    CategoryView: _CategoryView.default,
    AttributeTag: _ResourceAttributeTag.default,
    AreaOption: _AreaOption.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    typeName: {
      type: String,
      default: '点读书'
    },
    isShowArea: {
      type: Boolean,
      default: false
    },
    showVidSearch: {
      type: Boolean,
      default: false
    },
    showJsonData: {
      type: Boolean,
      default: false
    },
    // 视频列表的附件和试卷搜索
    showDownload: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: pickerShortcutsOnClickBuilder(7)
        }, {
          text: '最近一个月',
          onClick: pickerShortcutsOnClickBuilder(30)
        }, {
          text: '最近三个月',
          onClick: pickerShortcutsOnClickBuilder(90)
        }]
      },
      orderOptions: [{
        value: 'id',
        label: '默认排序'
      }, {
        value: 'resourceEncode',
        label: '作品编号'
      }, {
        value: 'title',
        label: '作品标题'
      }, {
        value: 'createdAt',
        label: '添加时间'
      }, {
        value: 'publishedAt',
        label: '发布时间'
      }, {
        value: 'updatedAt',
        label: '编辑时间'
      }],
      liveOrderOptions: [{
        value: 'id',
        label: '默认排序'
      }, {
        value: 'startTime',
        label: '直播时间'
      }, {
        value: 'resourceEncode',
        label: '作品编号'
      }, {
        value: 'title',
        label: '作品标题'
      }, {
        value: 'createdAt',
        label: '添加时间'
      }, {
        value: 'publishedAt',
        label: '发布时间'
      }, {
        value: 'updatedAt',
        label: '编辑时间'
      }]
    };
  },
  computed: {
    isLive: function isLive() {
      var liveFlag = false;

      if (this.typeName === '直播') {
        liveFlag = true;
      }

      return liveFlag;
    },
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    startDate: {
      get: function get() {
        if (!this.objectHasKey(this.valueProxy, 'createStartTime', 'createdEndTime')) {
          return undefined;
        } // 如果 value 不同时包括时间，则返回 undefined


        return [this.valueProxy.createStartTime, this.valueProxy.createdEndTime];
      },
      set: function set(time) {
        var _ref = time || [],
            _ref2 = (0, _slicedToArray2.default)(_ref, 2),
            createStartTime = _ref2[0],
            createdEndTime = _ref2[1];

        this.$emit('input', _objectSpread(_objectSpread({}, this.valueProxy), {}, {
          createStartTime: createStartTime,
          createdEndTime: createdEndTime
        }));
      }
    },
    endDate: {
      get: function get() {
        if (!this.objectHasKey(this.valueProxy, 'publishStartTime', 'publishEndTime')) {
          return undefined;
        } // 如果 value 不同时包括时间，则返回 undefined


        return [this.valueProxy.publishStartTime, this.valueProxy.publishEndTime];
      },
      set: function set(time) {
        var _ref3 = time || [],
            _ref4 = (0, _slicedToArray2.default)(_ref3, 2),
            publishStartTime = _ref4[0],
            publishEndTime = _ref4[1];

        this.$emit('input', _objectSpread(_objectSpread({}, this.valueProxy), {}, {
          publishStartTime: publishStartTime,
          publishEndTime: publishEndTime
        }));
      }
    },
    liveDate: {
      get: function get() {
        if (!this.objectHasKey(this.valueProxy, 'liveStartTime', 'liveEndTime')) {
          return undefined;
        } // 如果 value 不同时包括时间，则返回 undefined


        return [this.valueProxy.liveStartTime, this.valueProxy.liveEndTime];
      },
      set: function set(time) {
        var _ref5 = time || [],
            _ref6 = (0, _slicedToArray2.default)(_ref5, 2),
            liveStartTime = _ref6[0],
            liveEndTime = _ref6[1];

        this.$emit('input', _objectSpread(_objectSpread({}, this.valueProxy), {}, {
          liveStartTime: liveStartTime,
          liveEndTime: liveEndTime
        }));
      }
    },
    regimentationIds: function regimentationIds() {
      var _this$valueProxy = this.valueProxy,
          regimentationIds = _this$valueProxy.regimentationIds,
          secondRegimentationIds = _this$valueProxy.secondRegimentationIds;
      return [regimentationIds ? Number(regimentationIds) : '', secondRegimentationIds ? Number(secondRegimentationIds) : ''];
    }
  },
  methods: {
    /**
     * 判断一个对象是否包含指定的 key
     *
     * @param {object} obj
     * @param {string} keys
     * @returns {boolean}
     */
    objectHasKey: function objectHasKey(obj) {
      for (var _len = arguments.length, keys = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        keys[_key - 1] = arguments[_key];
      }

      return keys.reduce(function (hasVal, key) {
        return hasVal && !!obj[key];
      }, true);
    },

    /**
     * 类目的选中值发生变化
     * */
    categoryChange: function categoryChange(data) {
      this.valueProxy.regimentationIds = data.regimentationIds;
      this.valueProxy.secondRegimentationIds = data.secondRegimentationIds;
    }
  }
};
exports.default = _default2;