var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "audio-wrapper"
    },
    [
      _c(
        "div",
        { staticClass: "audio-wrapper-bg" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.audioInfo,
                rules: _vm.rules,
                "status-icon": "",
                "label-width": "100px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-audio-wrapper" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          基本信息\n          "),
                    _vm.openId && _vm.audioInfo.state
                      ? _c(
                          "div",
                          { staticClass: "state-bg" },
                          [
                            _c("span", { staticClass: "state-date" }, [
                              _vm._v(
                                _vm._s(_vm.statusList[0].dateLabel) +
                                  ":" +
                                  _vm._s(
                                    _vm.getDateDiff(
                                      _vm.audioInfo[_vm.statusList[0].dateName]
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "timer",
                                attrs: { effect: "dark", placement: "top" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "20px" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.statusList[0].dateLabel) +
                                        "：" +
                                        _vm._s(
                                          _vm._f("parseTime")(
                                            _vm.audioInfo[
                                              _vm.statusList[0].dateName
                                            ],
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.audioInfo.createdAt
                                      ? _c("span", [
                                          _vm._v(
                                            "创建时间：" +
                                              _vm._s(
                                                _vm._f("parseTime")(
                                                  _vm.audioInfo.createdAt,
                                                  "{y}-{m}-{d} {h}:{i}"
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tag",
                                  {
                                    staticClass: "state-tag",
                                    attrs: { type: _vm.statusList[0].typeName }
                                  },
                                  [_vm._v(_vm._s(_vm.statusList[0].btnName))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.audioInfo.resourceOpenId
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "作品ID", prop: "" } },
                        [
                          _vm.audioInfo.resourceOpenId
                            ? _c("div", { staticClass: "title-id" }, [
                                _vm._v(_vm._s(_vm.audioInfo.resourceOpenId))
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "音频名称", prop: "title" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "850px" },
                        attrs: {
                          disabled: _vm.allowEdit,
                          "show-word-limit": "",
                          maxlength: 100,
                          debounce: 500,
                          placeholder:
                            "请输入音频名称（建议字数在30以内，不超过60个字）",
                          "fetch-suggestions": _vm.searchTitle
                        },
                        model: {
                          value: _vm.audioInfo.title,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "title", $$v)
                          },
                          expression: "audioInfo.title"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作品编号" } },
                    [
                      _c("el-input", {
                        staticClass: "txt-width850",
                        attrs: {
                          placeholder: "请输入作品编号",
                          disabled: _vm.allowEdit,
                          maxlength: "50",
                          "show-word-limit": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.audioInfo.resourceEncode,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "resourceEncode", $$v)
                          },
                          expression: "audioInfo.resourceEncode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "音频上传" } },
                    [
                      _c("upload-audio", {
                        ref: "uploadAudioControl",
                        attrs: {
                          type: "audio",
                          disabledEdit: _vm.allowEdit,
                          "audio-id": _vm.audioInfo.vid
                        },
                        on: { getResult: _vm.getAudio }
                      }),
                      _vm._v(" "),
                      _c("download-resource-set", {
                        attrs: { "allow-edit": _vm.allowEdit },
                        model: {
                          value: _vm.audioInfo,
                          callback: function($$v) {
                            _vm.audioInfo = $$v
                          },
                          expression: "audioInfo"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "预览时长" } }, [
                    _c(
                      "div",
                      { staticClass: "preview-duration-bg" },
                      [
                        _c("el-input-number", {
                          attrs: {
                            disabled: _vm.allowEdit || !_vm.audioInfo.vid,
                            precision: 0,
                            step: 1,
                            max: _vm.maxpreviewLength,
                            min: 0,
                            "controls-position": "right"
                          },
                          model: {
                            value: _vm.audioInfo.previewLength,
                            callback: function($$v) {
                              _vm.$set(_vm.audioInfo, "previewLength", $$v)
                            },
                            expression: "audioInfo.previewLength"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "line" }, [_vm._v("秒")])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "导播打点" } }, [
                    _c("div", { staticClass: "dot-box" }, [
                      _c(
                        "div",
                        { staticClass: "dot-title" },
                        [
                          _c("el-switch", {
                            attrs: {
                              disabled: !_vm.audioInfo.vid || _vm.allowEdit,
                              "active-color": "#13ce66"
                            },
                            model: {
                              value: _vm.audioInfo.audioMarkEnable,
                              callback: function($$v) {
                                _vm.$set(_vm.audioInfo, "audioMarkEnable", $$v)
                              },
                              expression: "audioInfo.audioMarkEnable"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("video-dot", {
                            attrs: {
                              videoMarkEnable: _vm.audioInfo.audioMarkEnable,
                              disabledEdit: _vm.allowEdit,
                              videourl: _vm.resultData.fileURL,
                              markData: _vm.markData,
                              "resource-title": _vm.audioInfo.title,
                              videoId: _vm.openId
                            },
                            on: {
                              getMarkList: _vm.getMarkList,
                              "update:markData": function($event) {
                                _vm.markData = $event
                              },
                              "update:mark-data": function($event) {
                                _vm.markData = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "字幕上传" } }, [
                    !_vm.audioInfo.caption
                      ? _c("div", { staticClass: "file-upload-box" }, [
                          _c(
                            "div",
                            { staticClass: "file-upload-bg" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      _vm.uploadLrcDisabled || _vm.allowEdit
                                  }
                                },
                                [_vm._v("点击上传")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                ref: "uploadLrc",
                                staticClass: "file-upload",
                                attrs: {
                                  disabled:
                                    _vm.uploadLrcDisabled || _vm.allowEdit,
                                  accept: ".lrc,.txt",
                                  type: "file"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.uploadLrc($event)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "upload-txt-bg" }, [
                            _c("i", {
                              staticClass: "el-icon-info",
                              staticStyle: { color: "#409EFF" }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "upload-txt" }, [
                              _vm._v("仅限上传lrc、txt文件，且不超过1M。")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.audioInfo.caption,
                                expression: "audioInfo.caption"
                              }
                            ],
                            attrs: { type: "hidden" },
                            domProps: { value: _vm.audioInfo.caption },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.audioInfo,
                                  "caption",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      : _c("div", { staticClass: "file-completed-box2" }, [
                          _c("div", { staticClass: "file-txt" }, [
                            _c("i", { staticClass: "iconfont icon-lyric" }),
                            _vm._v(_vm._s(_vm.captionName)),
                            _c("i", {
                              staticClass: "el-icon-delete",
                              attrs: { disabled: _vm.uploadLrcDisabled },
                              on: { click: _vm.delCaption }
                            }),
                            _c("i", {
                              staticClass: "el-icon-download",
                              on: {
                                click: function($event) {
                                  return _vm.downLrc()
                                }
                              }
                            })
                          ])
                        ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "音频封面" } },
                    [
                      _c("cover-upload", {
                        attrs: {
                          path: _vm.path,
                          "partner-open-id": _vm.partnerOpenId,
                          coverarr: _vm.coverArr,
                          covertext: _vm.coverText,
                          type: _vm.AUDIO_ID.toString(),
                          disabledEdit: _vm.allowEdit
                        },
                        on: { deletecover: _vm.deletecoverFun },
                        model: {
                          value: _vm.audioInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "cover", $$v)
                          },
                          expression: "audioInfo.cover"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.audioInfo.cover,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "cover", $$v)
                          },
                          expression: "audioInfo.cover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "音频文稿", prop: "detail" } },
                    [
                      _c("editor-view", {
                        attrs: { readonly: _vm.allowEdit },
                        model: {
                          value: _vm.audioInfo.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "detail", $$v)
                          },
                          expression: "audioInfo.detail"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-text" }, [
                    _vm._v("拓展学习栏目")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "栏目内容" } },
                    [
                      _c("extended-learning", {
                        ref: "extendedLearning",
                        staticClass: "extended-learning",
                        attrs: { disable: _vm.allowEdit },
                        model: {
                          value: _vm.audioInfo.testData,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "testData", $$v)
                          },
                          expression: "audioInfo.testData"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          资源附件\n        ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "资源栏目名" } },
                    [
                      _c("el-input", {
                        staticClass: "txt-width850",
                        attrs: {
                          clearable: "",
                          placeholder: "请输入资源栏目名"
                        },
                        model: {
                          value: _vm.audioInfo.columnTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "columnTitle", $$v)
                          },
                          expression: "audioInfo.columnTitle"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "文件上传" } }, [
                    _c(
                      "div",
                      { staticClass: "txt-width850" },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "header-row-style": { height: "30px" },
                              "header-cell-style": { background: "#F5F7FA" },
                              data: _vm.attachmentList,
                              border: ""
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "作品名",
                                "min-width": "240"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(scope.row.display.title) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "作品编号", width: "300" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "resource-encode-flex" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "resource-encode" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.key.resourceEncode
                                                    ? scope.row.key
                                                        .resourceEncode
                                                    : "--"
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-copy encode-copy-icon",
                                            on: {
                                              click: function($event) {
                                                return _vm.copyResourceEncode(
                                                  scope.row.key.resourceEncode,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "操作", width: "100" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#409EFF" },
                                          attrs: {
                                            type: "text",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.previewResource(
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("预览")]
                                      ),
                                      _vm._v(" "),
                                      !_vm.allowEdit
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                color: "rgb(245, 108, 108)"
                                              },
                                              attrs: {
                                                type: "text",
                                                size: "small"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.delRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("移除")]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "btnBox" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.allowEdit,
                                  size: "small",
                                  type: "primary",
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.outerVisible = true
                                  }
                                }
                              },
                              [_vm._v("选择文件")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.allowEdit,
                                  size: "small",
                                  type: "danger",
                                  plain: ""
                                },
                                on: { click: _vm.delAll }
                              },
                              [_vm._v("全部删除")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("作品属性")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "同步" } },
                    [
                      _c("sync-view", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.audioInfo.tempCode,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "tempCode", $$v)
                          },
                          expression: "audioInfo.tempCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "types" } },
                    [
                      _c("works", {
                        ref: "worksView",
                        attrs: { isDisabled: _vm.allowEdit },
                        on: { "add-classtion": _vm.addClass }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        attrs: { value: _vm.audioInfo.types }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版次" } },
                    [
                      _c("edition-box", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.audioInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "periodCode", $$v)
                          },
                          expression: "audioInfo.periodCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.audioInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.audioInfo, "periodCode", $$v)
                          },
                          expression: "audioInfo.periodCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "类目", prop: "secondRegimentationIds" }
                    },
                    [
                      _c("category-view", {
                        staticStyle: { width: "850px" },
                        attrs: { "is-disabled": _vm.allowEdit },
                        on: { "category-change": _vm.categoryChange },
                        model: {
                          value: _vm.audioInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.audioInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "audioInfo.secondRegimentationIds"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.audioInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.audioInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "audioInfo.secondRegimentationIds"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "property-box" },
                    [
                      _c("el-collapse-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowProperty,
                                expression: "isShowProperty"
                              }
                            ],
                            staticClass: "property-item-bg"
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "地区" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择地区"
                                    },
                                    model: {
                                      value: _vm.areaValue,
                                      callback: function($$v) {
                                        _vm.areaValue = $$v
                                      },
                                      expression: "areaValue"
                                    }
                                  },
                                  _vm._l(_vm.areaList, function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "标签" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "allow-create": "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择商品标签"
                                    },
                                    on: { change: _vm.changeTags },
                                    model: {
                                      value: _vm.tagValue,
                                      callback: function($$v) {
                                        _vm.tagValue = $$v
                                      },
                                      expression: "tagValue"
                                    }
                                  },
                                  _vm._l(_vm.tagList, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        class: [
                          "property-item-arrow",
                          _vm.isShowProperty
                            ? "el-icon-arrow-up"
                            : "el-icon-arrow-down"
                        ],
                        on: {
                          click: function($event) {
                            _vm.isShowProperty = !_vm.isShowProperty
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("商品设置")]),
                  _vm._v(" "),
                  _c("goods-set", {
                    ref: "commodity",
                    attrs: {
                      "type-name": _vm.typeName,
                      partnerOpenId: _vm.partnerOpenId,
                      "independent-sale": _vm.audioInfo.independentSale,
                      disabledEdit: _vm.allowEdit,
                      "resource-open-id": _vm.openId
                    },
                    on: { "official-partner": _vm.getOfficialPartner }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("works-operation-buttton", {
                attrs: {
                  openId: _vm.openId,
                  allowEdit: _vm.allowEdit,
                  operation: _vm.operation,
                  status: _vm.audioInfo.state,
                  passBtn: _vm.passBtn,
                  refuseBtn: _vm.refuseBtn,
                  restoreBtn: _vm.restoreBtn,
                  publishBtn: _vm.publishBtn,
                  saveBtn: _vm.saveBtn,
                  typeName: _vm.typeName,
                  saveDraftBtn: _vm.saveDraftBtn
                },
                on: {
                  closeView: _vm.closeView,
                  updateEdit: _vm.updateEdit,
                  passItem: _vm.passItem,
                  handelRefuse: _vm.handelRefuse,
                  handelSaveAndReview: _vm.handelSaveAndReview,
                  handleBookRestore: _vm.handleBookRestore,
                  saveData: _vm.saveData,
                  saveDraft: _vm.saveDraft
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "refuse-book-bg",
          attrs: { visible: _vm.dialogRefuse, title: "驳回申请" },
          on: {
            "update:visible": function($event) {
              _vm.dialogRefuse = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "refuseForm",
              attrs: {
                model: _vm.refuseForm,
                rules: _vm.refuseRules,
                "label-width": "110px",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "reason" } },
                [
                  _c("el-input", {
                    staticClass: "txt-reason",
                    attrs: {
                      autosize: { minRows: 1, maxRows: 1 },
                      type: "textarea",
                      placeholder: "请简述驳回原因，不超过20个字"
                    },
                    model: {
                      value: _vm.refuseForm.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.refuseForm, "reason", $$v)
                      },
                      expression: "refuseForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRefuse = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.refuseObj()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.outerVisible },
        on: {
          "update:visible": function($event) {
            _vm.outerVisible = $event
          }
        },
        model: {
          value: _vm.attachmentObj,
          callback: function($$v) {
            _vm.attachmentObj = $$v
          },
          expression: "attachmentObj"
        }
      }),
      _vm._v(" "),
      _c("resource-share", {
        attrs: {
          visible: _vm.isShowSharePreview,
          "link-type": _vm.linkType,
          "share-obj": _vm.shareResource
        },
        on: {
          "update:visible": function($event) {
            _vm.isShowSharePreview = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }