var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderDetailWrap" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": "",
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            width: "1192px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "display_flex justify_content",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c("div", { staticClass: "orderDetailTitle" }, [
                _vm._v("订单详情")
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-right": "20px" } }, [
                _vm.orderdetailData.status === 1
                  ? _c(
                      "div",
                      { staticClass: "display_flex align_items" },
                      [
                        _c("div", { staticClass: "buttonText" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("parseTime")(
                                _vm.showVal(_vm.orderdetailData.createdAt),
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "warning",
                              disabled: "",
                              plain: ""
                            }
                          },
                          [_vm._v("待支付")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderdetailData.status === 2
                  ? _c(
                      "div",
                      { staticClass: "display_flex align_items" },
                      [
                        _c("div", { staticClass: "buttonText" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("parseTime")(
                                _vm.showVal(_vm.orderdetailData.payedAt),
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "success",
                              disabled: "",
                              plain: ""
                            }
                          },
                          [_vm._v("已支付")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderdetailData.status === 4
                  ? _c(
                      "div",
                      { staticClass: "display_flex align_items" },
                      [
                        _c("div", { staticClass: "buttonText" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("parseTime")(
                                _vm.showVal(_vm.orderdetailData.updatedAt),
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: "",
                              plain: ""
                            }
                          },
                          [_vm._v("已退款")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderdetailData.status === 5
                  ? _c(
                      "div",
                      { staticClass: "display_flex align_items" },
                      [
                        _c("div", { staticClass: "buttonText" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("parseTime")(
                                _vm.showVal(_vm.orderdetailData.createdAt),
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: "",
                              plain: ""
                            }
                          },
                          [_vm._v("已失效")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "orderDetail",
              attrs: { type: "border-card" }
            },
            [
              _c("div", { staticStyle: { padding: "0 20px" } }, [
                _c("div", { staticClass: "flexItem display_flex" }, [
                  _vm.orderdetailData.resourceInfo
                    ? _c(
                        "div",
                        { staticClass: "marginRight_10" },
                        [
                          _vm.orderdetailData.resourceType === 5
                            ? _c("el-image", {
                                staticClass: "coverStyvideo",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.getImgUrl(
                                    "mp/orderCover/jinxinbiCover.png",
                                    _vm.thumbnailStyle
                                  )
                                }
                              })
                            : _vm.orderdetailData.resourceType === 6
                            ? _c("el-image", {
                                staticClass: "coverStyvideo",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.getImgUrl(
                                    "mp/orderCover/vipCover.png",
                                    _vm.thumbnailStyle
                                  )
                                }
                              })
                            : _c("el-image", {
                                staticClass: "coverStyvideo",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.getImgUrl(
                                    _vm.orderdetailData.resourceInfo.cover,
                                    _vm.thumbnailStyle
                                  )
                                }
                              })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "subTitle" }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "font-size": "14px", color: "#303133" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.showVal(
                              _vm.orderdetailData.downloadResourceFlag === 1
                                ? _vm.orderdetailData.resourceTitle + "_下载"
                                : _vm.orderdetailData.resourceTitle
                            )
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "commonfontsize display_flex justify_content align_items"
                      },
                      [
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.resourcetypetoStr(
                                  _vm.orderdetailData.resourceType
                                )
                              )
                            )
                          ]),
                          _vm._v(
                            "\n                | 商品ID：" +
                              _vm._s(
                                _vm.showVal(_vm.orderdetailData.commodityOpenId)
                              ) +
                              " | 作品 Open ID：" +
                              _vm._s(
                                _vm.showVal(_vm.orderdetailData.resourceOpenId)
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toCommoditydetail(
                                        _vm.orderdetailData.commodityOpenId,
                                        _vm.orderdetailData.orderId
                                      )
                                    }
                                  }
                                },
                                [_vm._v("商品详情")]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.orderdetailData.gifts
                  ? _c("div", { staticClass: "listOfGifts flexItem" }, [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", { staticClass: "line" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "listOfGiftsTitle" }, [
                          _vm._v("赠品清单")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" }
                        },
                        _vm._l(_vm.gifts.data, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "giftsSty" },
                            [
                              item && item.resourceInfo
                                ? _c("div", [
                                    item.resourceType === 1 ||
                                    item.resourceType === 4 ||
                                    item.resourceType === 2
                                      ? _c(
                                          "div",
                                          { staticClass: "fleximgitem" },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "80px",
                                                height: "60px"
                                              },
                                              attrs: {
                                                fit: "cover",
                                                src: _vm.getImgUrl(
                                                  item.resourceInfo.cover,
                                                  _vm.thumbnailStyle
                                                ),
                                                alt: ""
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fleximgitemTitle"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fleximgitemTitleSty"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.resourceTitle)
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "commonfontsize"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.resourcetypetoStr(
                                                            item.resourceType
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.resourceType === 3
                                      ? _c(
                                          "div",
                                          { staticClass: "fleximgitem" },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "80px",
                                                height: "60px"
                                              },
                                              attrs: {
                                                fit: "cover",
                                                src: _vm.getImgUrl(
                                                  item.resourceInfo.cover,
                                                  _vm.thumbnailStyle
                                                ),
                                                alt: ""
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fleximgitemTitle"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fleximgitemTitleSty"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.resourceTitle)
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "commonfontsize"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.resourcetypetoStr(
                                                            item.resourceType
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.resourceType === 5
                                      ? _c(
                                          "div",
                                          { staticClass: "fleximgitem" },
                                          [
                                            _c("el-image", {
                                              staticClass: "audioBookcover",
                                              staticStyle: {
                                                width: "80px",
                                                height: "60px"
                                              },
                                              attrs: {
                                                fit: "cover",
                                                src: _vm.getImgUrl(
                                                  "mp/orderCover/jinxinbiCover.png",
                                                  _vm.thumbnailStyle
                                                ),
                                                alt: ""
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fleximgitemTitle"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fleximgitemTitleSty"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.resourceTitle)
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "commonfontsize"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("金星币")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.resourceType === 6
                                      ? _c(
                                          "div",
                                          { staticClass: "fleximgitem" },
                                          [
                                            _c("el-image", {
                                              staticClass: "audioBookcover",
                                              staticStyle: {
                                                width: "80px",
                                                height: "60px"
                                              },
                                              attrs: {
                                                fit: "cover",
                                                src: _vm.getImgUrl(
                                                  "mp/orderCover/vipCover.png",
                                                  _vm.thumbnailStyle
                                                ),
                                                alt: ""
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fleximgitemTitle"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fleximgitemTitleSty"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.resourceTitle)
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "commonfontsize"
                                                  },
                                                  [_c("span", [_vm._v("VIP")])]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "orderInfo flexItem" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticClass: "line" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "listOfGiftsTitle" }, [
                      _vm._v("订单信息")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "commomCol " }, [
                    _c("div", { staticStyle: { width: "300px" } }, [
                      _c("div", [
                        _vm._v(
                          "订单号：" +
                            _vm._s(_vm.showVal(_vm.orderdetailData.orderId))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "overflowEllipsis",
                          staticStyle: { width: "1000px" }
                        },
                        [
                          _vm._v(
                            "用户备注：" +
                              _vm._s(
                                _vm.showVal(_vm.orderdetailData.buyUserRemark)
                              )
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "buyUserSty" }, [
                      _vm._v(
                        "下单时间：" +
                          _vm._s(_vm.showVal(_vm.orderdetailData.createdAt))
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "300px" } }, [
                      _vm._v(
                        "购买渠道：" +
                          _vm._s(_vm.showChannel(_vm.orderdetailData.channel))
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "orderInfo flexItem" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticClass: "line" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "listOfGiftsTitle" }, [
                      _vm._v("\n              交易信息\n            ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "commomCol" }, [
                    _c("div", { staticClass: "commomColSty" }, [
                      _c("div", [
                        _vm._v(
                          "购买方式：" +
                            _vm._s(
                              _vm.buyTypetoStr(_vm.orderdetailData.buyType)
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "340px" } }, [
                        _vm._v(
                          "聚合支付订单号：" +
                            _vm._s(_vm.showVal(_vm.orderdetailData.mixOrderId))
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "支付时间：" +
                            _vm._s(
                              _vm._f("parseTime")(
                                _vm.showVal(_vm.orderdetailData.payedAt),
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "buyUserSty" }, [
                      _c("div", [
                        _vm._v(
                          "支付方式：" +
                            _vm._s(
                              _vm.showVal(_vm.orderdetailData.payChannelName)
                            ) +
                            " (手续费" +
                            _vm._s(
                              Number(_vm.orderdetailData.commissionRate) === 0
                                ? "--"
                                : _vm
                                    .showVal(
                                      _vm.orderdetailData.commissionRate * 1000
                                    )
                                    .toFixed(0) + "‰"
                            ) +
                            ")"
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "支付渠道订单号：" +
                            _vm._s(
                              _vm.showVal(_vm.orderdetailData.channelOrderId)
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "commomColSty" })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "orderInfo flexItem" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticClass: "line" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "listOfGiftsTitle" }, [
                      _vm._v("买家信息")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "commomCol" }, [
                    _c("div", { staticClass: "commomColSty" }, [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm._v(
                          "买家：" +
                            _vm._s(
                              _vm.showVal(
                                _vm.orderdetailData.buyUserInfo &&
                                  _vm.orderdetailData.buyUserInfo.displayName
                              )
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "在本店消费次数：" +
                            _vm._s(_vm.showVal(_vm.consumeCount.data))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "buyUserSty" }, [
                      _c("div", [
                        _vm._v(
                          "买家ID：" +
                            _vm._s(
                              _vm.showVal(_vm.orderdetailData.buyUserOpenId)
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("您的粉丝："),
                        _vm.orderdetailData.fansOnBuy === true
                          ? _c("span", [_vm._v("是")])
                          : _vm._e(),
                        _vm.orderdetailData.fansOnBuy === false
                          ? _c("span", [_vm._v("不是")])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "commomColSty" }, [
                      _c("div", [
                        _vm._v("以VIP购买："),
                        _vm.orderdetailData.vipOnBuy === true
                          ? _c("span", [_vm._v("是")])
                          : _vm._e(),
                        _vm.orderdetailData.vipOnBuy === false
                          ? _c("span", [_vm._v("不是")])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("当前年级：\n                "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.showVal(_vm.orderdetailData.sectionName)
                            ) + " / "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.showVal(_vm.orderdetailData.gradeName)) +
                              " / "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.showVal(_vm.orderdetailData.volumeName)
                            ) + " "
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "orderInfo flexItem " }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticClass: "line" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "listOfGiftsTitle" }, [
                      _vm._v("邮寄信息")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "listOfGiftsInfo" },
                      [
                        _c("i", {
                          staticClass: "el-icon-edit",
                          staticStyle: {
                            color: "#606266",
                            padding: "0 5px",
                            cursor: "pointer"
                          },
                          on: { click: _vm.openDialog }
                        }),
                        _vm._v(" "),
                        _c(
                          "el-dialog",
                          {
                            attrs: {
                              "append-to-body": "",
                              id: "dialogBody",
                              visible: _vm.dialogFormVisible,
                              "close-on-click-modal": false,
                              "close-on-press-escape": false,
                              title: "编辑邮寄信息",
                              width: "610px"
                            },
                            on: {
                              "update:visible": function($event) {
                                _vm.dialogFormVisible = $event
                              }
                            }
                          },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "ruleForm",
                                staticClass: "demo-ruleForm",
                                staticStyle: { padding: "0 20px" },
                                attrs: {
                                  model: _vm.ruleForm,
                                  rules: _vm.rules,
                                  "status-icon": "",
                                  "label-width": "110px"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否需要邮寄",
                                      prop: "needAddress"
                                    }
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: _vm.ruleForm.needAddress,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "needAddress",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.needAddress"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.ruleForm.needAddress
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              id: "telPhone",
                                              label: "联系电话",
                                              prop: "addressPhone"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请填写联系电话"
                                              },
                                              model: {
                                                value:
                                                  _vm.ruleForm.addressPhone,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "addressPhone",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.addressPhone"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "收件人",
                                              prop: "addressRecipient"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请填写收件人"
                                              },
                                              model: {
                                                value:
                                                  _vm.ruleForm.addressRecipient,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "addressRecipient",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.addressRecipient"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("address-select", {
                                          attrs: {
                                            id: "cityData",
                                            area: _vm.ruleForm
                                          },
                                          on: {
                                            provincedata: _vm.provincedata,
                                            citydata: _vm.citydata,
                                            districtdata: _vm.districtdata
                                          },
                                          model: {
                                            value: _vm.ruleForm,
                                            callback: function($$v) {
                                              _vm.ruleForm = $$v
                                            },
                                            expression: "ruleForm"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "详细地址",
                                              prop: "addressDetail"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { type: "textarea" },
                                              model: {
                                                value:
                                                  _vm.ruleForm.addressDetail,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "addressDetail",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.addressDetail"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div")
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dialog-footer",
                                attrs: { slot: "footer" },
                                slot: "footer"
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        _vm.dialogFormVisible = false
                                      }
                                    }
                                  },
                                  [_vm._v("取 消")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.submitForm("ruleForm")
                                      }
                                    }
                                  },
                                  [_vm._v("确定")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "commonFont" },
                    [
                      _c("div", { staticClass: "commomCol" }, [
                        _c("div", { staticClass: "commomColSty" }, [
                          _c("div", [
                            _vm._v(
                              "是否需要邮寄：" +
                                _vm._s(
                                  _vm.orderdetailData.needAddress ? "是" : "否"
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _vm.orderdetailData.needAddress === true
                            ? _c("div", { staticStyle: { width: "700px" } }, [
                                _vm._v(
                                  "\n                  收货地址：" +
                                    _vm._s(
                                      _vm.orderdetailData.addressProvince
                                    ) +
                                    "\n                  " +
                                    _vm._s(_vm.orderdetailData.addressCity) +
                                    "\n                  " +
                                    _vm._s(_vm.orderdetailData.addressCounty) +
                                    "\n                  " +
                                    _vm._s(_vm.orderdetailData.addressStreet) +
                                    "\n                  " +
                                    _vm._s(_vm.orderdetailData.addressDetail) +
                                    "\n                "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _vm.orderdetailData.needAddress === true
                          ? _c("div", { staticClass: "buyUserSty" }, [
                              _c("div", [
                                _vm._v(
                                  "收货人：" +
                                    _vm._s(
                                      _vm.showVal(
                                        _vm.orderdetailData.addressRecipient
                                      )
                                    )
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderdetailData.needAddress === true
                          ? _c("div", { staticClass: "commomColSty" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("div", [_vm._v("物流信息：")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _vm.orderdetailData.postCompanyName
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.showVal(
                                                  _vm.orderdetailData
                                                    .postCompanyName
                                                )
                                              ) + "-"
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.orderdetailData.trackingNumber
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.showVal(
                                                  _vm.orderdetailData
                                                    .trackingNumber
                                                )
                                              )
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-edit",
                                          staticStyle: {
                                            color: "#606266",
                                            padding: "0 5px",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.updataAddress(
                                                _vm.orderdetailData
                                                  .postCompanyId,
                                                _vm.orderdetailData
                                                  .postCompanyName,
                                                _vm.orderdetailData
                                                  .trackingNumber
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "联系电话：" +
                                    _vm._s(
                                      _vm.showVal(
                                        _vm.orderdetailData.addressPhone
                                      )
                                    )
                                )
                              ])
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dialog",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.addressloadingdialogFormVisible,
                              expression: "addressloadingdialogFormVisible"
                            }
                          ],
                          attrs: {
                            "append-to-body": "",
                            title: "设置物流信息",
                            visible: _vm.addressdialogFormVisible,
                            width: "400px",
                            "close-on-click-modal": false,
                            "close-on-press-escape": false
                          },
                          on: {
                            "update:visible": function($event) {
                              _vm.addressdialogFormVisible = $event
                            }
                          }
                        },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "addressdialogFormVisible",
                              attrs: { model: _vm.trackingNumberForm }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "物流渠道",
                                    prop: "postCompanyName",
                                    "label-width": "90px",
                                    rules: [
                                      {
                                        required: true,
                                        message: "物流渠道不能为空"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value:
                                          _vm.trackingNumberForm
                                            .postCompanyName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.trackingNumberForm,
                                            "postCompanyName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "trackingNumberForm.postCompanyName"
                                      }
                                    },
                                    _vm._l(_vm.postOptions, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.expName,
                                          value: item.expName
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "trackingNumber",
                                    label: "运单号",
                                    "label-width": "90px",
                                    rules: [
                                      {
                                        required: true,
                                        message: "运单号不能为空"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      type: "text",
                                      placeholder: "运单号"
                                    },
                                    model: {
                                      value:
                                        _vm.trackingNumberForm.trackingNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trackingNumberForm,
                                          "trackingNumber",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "trackingNumberForm.trackingNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "footer" },
                              slot: "footer"
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.addressdialogFormVisible = false
                                    }
                                  }
                                },
                                [_vm._v("取 消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updataAddressNumber(
                                        "addressdialogFormVisible"
                                      )
                                    }
                                  }
                                },
                                [_vm._v("确定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "orderInfo flexItem" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticClass: "line" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "listOfGiftsTitle" }, [
                      _vm._v("订单备注")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "commomCol" }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("div", [
                          _vm._v(
                            "订单备注：" +
                              _vm._s(_vm.showVal(_vm.orderdetailData.remarks))
                          ),
                          _c("i", {
                            staticClass: "el-icon-edit",
                            staticStyle: {
                              color: "#606266",
                              padding: "0 5px",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                _vm.dialogRemarksinformationVisible = true
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-dialog",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingdialogFormVisible,
                                expression: "loadingdialogFormVisible"
                              }
                            ],
                            attrs: {
                              "append-to-body": "",
                              title: "订单备注",
                              visible: _vm.dialogRemarksinformationVisible,
                              width: "30%",
                              "close-on-click-modal": false,
                              "close-on-press-escape": false
                            },
                            on: {
                              "update:visible": function($event) {
                                _vm.dialogRemarksinformationVisible = $event
                              }
                            }
                          },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "orderRecord",
                                staticStyle: { padding: "0 20px" },
                                attrs: { model: _vm.form }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "orderReason",
                                      rules: [
                                        {
                                          required: true,
                                          message: "订单备注不能为空"
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        placeholder: "请填写订单备注信息"
                                      },
                                      model: {
                                        value: _vm.form.orderReason,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "orderReason",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.orderReason"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dialog-footer",
                                attrs: { slot: "footer" },
                                slot: "footer"
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        _vm.dialogRemarksinformationVisible = false
                                      }
                                    }
                                  },
                                  [_vm._v("取 消")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", plain: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.remarksinfo("orderRecord")
                                      }
                                    }
                                  },
                                  [_vm._v("确定")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.orderdetailData.buyType === 1 ||
                _vm.orderdetailData.buyType === 6 ||
                _vm.orderdetailData.buyType === 7 ||
                _vm.orderdetailData.buyType === 8
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          width: "100%"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "OrderamountItem order-c",
                            staticStyle: { "margin-right": "10px" }
                          },
                          [
                            _c("div", { staticClass: "OrderamountTitle" }, [
                              _c("div", [_vm._v("平台收入")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "rgba(144,147,153,1)",
                                    "font-size": "12px",
                                    display: "flex"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                ¥" +
                                      _vm._s(
                                        _vm.showVal(
                                          Number(
                                            _vm.orderdetailData.payPrice / 100
                                          ).toFixed(2)
                                        )
                                      ) +
                                      "(订单金额) -\n                ¥" +
                                      _vm._s(
                                        _vm.showVal(
                                          Number(
                                            _vm.orderdetailData.realIncome / 100
                                          ).toFixed(2)
                                        )
                                      ) +
                                      "(商户实际收入) -\n                ¥" +
                                      _vm._s(
                                        _vm.showVal(
                                          Number(
                                            _vm.orderdetailData.commission / 100
                                          ).toFixed(2)
                                        )
                                      ) +
                                      "(手续费) -\n                ¥" +
                                      _vm._s(
                                        _vm.showVal(
                                          Number(
                                            _vm.orderdetailData
                                              .distributionAmount / 100
                                          ).toFixed(2)
                                        )
                                      ) +
                                      "(分销金额)\n              "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "OrderamountItemRight" }, [
                              _c("div", { staticClass: "commonFlex" }, [
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "24px" } },
                                  [
                                    _vm._v(
                                      "\n                  ¥" +
                                        _vm._s(
                                          (
                                            (_vm.orderdetailData.payPrice -
                                              _vm.orderdetailData.realIncome -
                                              _vm.orderdetailData.commission) /
                                            100
                                          ).toFixed(2)
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "OrderamountItem order-c",
                            staticStyle: { "margin-left": "10px" }
                          },
                          [
                            _c("div", { staticClass: "OrderamountTitle" }, [
                              _c("div", [_vm._v("商户实际收入(元)")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "rgba(144,147,153,1)",
                                    "font-size": "12px",
                                    display: "flex"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.showVal(
                                          _vm.orderdetailData.incomeFormula
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "OrderamountItemRight" }, [
                              _c("div", { staticClass: "commonFlex" }, [
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "24px" } },
                                  [
                                    _vm._v(
                                      "\n                  ¥" +
                                        _vm._s(
                                          _vm.showVal(
                                            Number(
                                              _vm.orderdetailData.realIncome /
                                                100
                                            ).toFixed(2)
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderdetailData.buyType === 2
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          width: "100%"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "OrderamountItem order-c",
                            staticStyle: { "margin-right": "10px" }
                          },
                          [
                            _c("div", { staticClass: "OrderamountTitle" }, [
                              _c("div", [_vm._v("订单金额(金星币)")])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "OrderamountItemRight" }, [
                              _c("div", { staticClass: "commonFlex" }, [
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "24px" } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.showVal(
                                            Math.ceil(
                                              Number(
                                                _vm.orderdetailData.payPrice /
                                                  10
                                              )
                                            )
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t-block1" }, [
                _c("div", [
                  _vm.orderdetailData.status === 2 &&
                  !(
                    _vm.orderdetailData.buyType == 1 &&
                    _vm.orderdetailData.payType == "applepay_iap"
                  ) &&
                  !_vm.RefundStstus
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "refund",
                              attrs: { type: "danger", plain: "" },
                              on: { click: _vm.openDialogRefund }
                            },
                            [_vm._v("退款")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.RefundStstus
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "112px" },
                              attrs: { type: "danger", plain: "", disabled: "" }
                            },
                            [_vm._v("已发起退款")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderdetailData.status === 4 &&
                  _vm.orderdetailData.orderRefund
                    ? _c(
                        "div",
                        { staticStyle: { display: "flex", color: "#606266" } },
                        [
                          _c("div", [
                            _vm._v(
                              "退款单号：" +
                                _vm._s(
                                  _vm.showVal(
                                    _vm.orderdetailData.orderRefund
                                      .refundOrderNumber
                                  )
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { padding: "0 10px" } }, [
                            _vm._v(
                              "退款原因：" +
                                _vm._s(
                                  _vm.showVal(
                                    _vm.orderdetailData.orderRefund.refundReason
                                  )
                                )
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("div", { staticClass: "actualTitle" }, [
                      _vm.orderdetailData.buyType === 1 ||
                      _vm.orderdetailData.buyType === 6 ||
                      _vm.orderdetailData.buyType === 7 ||
                      _vm.orderdetailData.buyType === 8
                        ? _c("div", [_vm._v("订单金额(元)")])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.orderdetailData.buyType === 1 ||
                    _vm.orderdetailData.buyType === 6 ||
                    _vm.orderdetailData.buyType === 7 ||
                    _vm.orderdetailData.buyType === 8
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              margin: "0 10px",
                              color: "#909399",
                              "font-size": "12px"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.showVal(
                                      _vm.orderdetailData.payFormulaSimple
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#303133",
                          "font-size": "14px",
                          "font-weight": "bold"
                        }
                      },
                      [
                        _vm.orderdetailData.buyType === 1 ||
                        _vm.orderdetailData.buyType === 6 ||
                        _vm.orderdetailData.buyType === 7 ||
                        _vm.orderdetailData.buyType === 8
                          ? _c("div", [
                              _c("div", [
                                _vm._v(
                                  "\n                ¥" +
                                    _vm._s(
                                      _vm.showVal(
                                        Number(
                                          _vm.orderdetailData.payPrice / 100
                                        ).toFixed(2)
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "listOfGiftsInfo" },
                [
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        "append-to-body": "",
                        id: "dialogBodyeRfund",
                        visible: _vm.dialogFormVisibleRefund,
                        title: "订单退款",
                        width: "610px",
                        "close-on-click-modal": false,
                        "close-on-press-escape": false
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.dialogFormVisibleRefund = $event
                        }
                      }
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ruleFormRefund",
                          staticClass: "demo-ruleForm",
                          staticStyle: { padding: "20px" },
                          attrs: {
                            model: _vm.ruleFormRefund,
                            rules: _vm.rules,
                            "status-icon": ""
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { display: "flex" },
                              attrs: { label: "退款原因", prop: "refund" }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "480px" },
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请简要描述退款原因"
                                },
                                model: {
                                  value: _vm.ruleFormRefund.refund,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleFormRefund, "refund", $$v)
                                  },
                                  expression: "ruleFormRefund.refund"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("verify", {
                            ref: "phoneCode",
                            on: {
                              "phone-code": _vm.phoneCode,
                              keyupEnter: function($event) {
                                return _vm.submitFormRefund("ruleFormRefund")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer"
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { loading: _vm.disabled, type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.submitFormRefund("ruleFormRefund")
                                }
                              }
                            },
                            [_vm._v("确定")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.dialogFormVisibleRefund = false
                                }
                              }
                            },
                            [_vm._v("取 消")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("commodity-detail", {
            attrs: {
              visible: _vm.commodityDetailDia,
              commodityOpenId: _vm.commodityOpenId,
              orderId: _vm.orderId
            },
            on: {
              "update:visible": function($event) {
                _vm.commodityDetailDia = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }