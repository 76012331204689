var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container-mannger" },
    [
      _c("div", { staticClass: "dashboard-text" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.toAddadmin()
                  }
                }
              },
              [_vm._v("添加")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.exportAdminHandle }
              },
              [_vm._v("导入")]
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择系统" },
                on: { change: _vm.changeSelectedSystem },
                model: {
                  value: _vm.subordinateSystemId,
                  callback: function($$v) {
                    _vm.subordinateSystemId = $$v
                  },
                  expression: "subordinateSystemId"
                }
              },
              _vm._l(_vm.subordinateSystemOptions, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.title, value: item.value }
                })
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择", clearable: "" },
                on: { change: _vm.searchTableList },
                model: {
                  value: _vm.query.roleId,
                  callback: function($$v) {
                    _vm.$set(_vm.query, "roleId", $$v)
                  },
                  expression: "query.roleId"
                }
              },
              _vm._l(_vm.roleData, function(item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.title, value: item.id }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-input", {
              staticStyle: { width: "280px", "margin-left": "10px" },
              attrs: { placeholder: "请输入姓名、id、手机号、或标签" },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchTableList($event)
                }
              },
              model: {
                value: _vm.query.keyword,
                callback: function($$v) {
                  _vm.$set(_vm.query, "keyword", $$v)
                },
                expression: "query.keyword"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary" },
                on: { click: _vm.searchTableList }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableData" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "multipleTable",
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                height: "calc(100vh - 205px)"
              },
              on: {
                "filter-change": _vm.filterMethod,
                "selection-change": _vm.handleSelectionChange
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "姓名", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.name))]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户id", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.userOpenId))]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "roleNames", label: "角色", width: "200" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号", width: "200" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标签", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          scope.row.tags && scope.row.tags.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "tagList",
                                  staticStyle: { display: "inline-flex" }
                                },
                                [
                                  scope.row.tags.length < 6
                                    ? _c(
                                        "div",
                                        _vm._l(scope.row.tags, function(
                                          tagItem,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticStyle: {
                                                display: "inline-flex",
                                                "margin-right": "5px"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    closable: true
                                                  },
                                                  on: {
                                                    close: function($event) {
                                                      return _vm.deletedUserTag(
                                                        tagItem,
                                                        scope.row.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(tagItem))]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "el-collapse",
                                            {
                                              staticStyle: {
                                                display: "inline-flex"
                                              },
                                              attrs: { accordion: "" }
                                            },
                                            [
                                              _c("el-collapse-item", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-flex"
                                                    },
                                                    attrs: { slot: "title" },
                                                    slot: "title"
                                                  },
                                                  _vm._l(
                                                    scope.row.tags.slice(0, 5),
                                                    function(tagItem, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            "margin-right":
                                                              "5px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-tag",
                                                            {
                                                              attrs: {
                                                                size: "mini",
                                                                closable: true
                                                              },
                                                              on: {
                                                                close: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deletedUserTag(
                                                                    tagItem,
                                                                    scope.row.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  tagItem
                                                                ) +
                                                                  ">" +
                                                                  _vm._s(
                                                                    tagItem
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-flex"
                                                    }
                                                  },
                                                  _vm._l(
                                                    scope.row.tags.slice(
                                                      5,
                                                      scope.row.tags.length
                                                    ),
                                                    function(tagItem, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            "margin-right":
                                                              "5px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-tag",
                                                            {
                                                              attrs: {
                                                                size: "mini",
                                                                closable: true
                                                              },
                                                              on: {
                                                                close: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deletedUserTag(
                                                                    tagItem,
                                                                    scope.row.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(tagItem)
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    trigger: "click"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                        width: "210px"
                                      }
                                    },
                                    _vm._l(_vm.UserTagList, function(tagItem) {
                                      return _c(
                                        "div",
                                        {
                                          key: tagItem.id,
                                          staticStyle: {
                                            margin: "0 5px 5px 0",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addUserTagApi(
                                                tagItem,
                                                scope.row.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("tags", {
                                            attrs: {
                                              tagColor: tagItem.tagColor,
                                              tagName: tagItem.tagName,
                                              className: tagItem.className,
                                              closable: false
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "addTags",
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    },
                                    [_vm._v("添加标签")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  align: "right",
                  fixed: "right",
                  width: "120",
                  label: "操作",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#f56c6c" },
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("移除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "elPagination" },
            [
              _c("el-pagination", {
                staticStyle: { float: "right" },
                attrs: {
                  background: "",
                  "current-page": _vm.query.page,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.query.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.totalElements
                },
                on: {
                  "update:currentPage": function($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:current-page": function($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:pageSize": function($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  "update:page-size": function($event) {
                    return _vm.$set(_vm.query, "size", $event)
                  },
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "导入管理员",
            visible: _vm.dialogVisible,
            width: "520px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员名单", prop: "fileValue" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadExcel",
                      attrs: {
                        action: "",
                        limit: 1,
                        accept: ".XLSX,.EXCEL",
                        "auto-upload": false,
                        "on-remove": _vm.handleRemove,
                        "before-upload": _vm.beforeUploadFile,
                        "on-change": _vm.httpRequest
                      },
                      model: {
                        value: _vm.form.fileValue,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "fileValue", $$v)
                        },
                        expression: "form.fileValue"
                      }
                    },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("选择文件")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          staticStyle: {
                            display: "inline-flex",
                            "margin-left": "10px"
                          },
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v("只能上传excel文件，且不超过10M")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角色", prop: "roles" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.form.roles,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "roles", $$v)
                        },
                        expression: "form.roles"
                      }
                    },
                    _vm._l(_vm.roleData, function(item, index) {
                      return _c(
                        "el-checkbox",
                        { key: index, attrs: { label: item.id } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.text) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.form.tags,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "tags", $$v)
                        },
                        expression: "form.tags"
                      }
                    },
                    _vm._l(_vm.UserTagList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.tagName, value: item.tagName }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    disabled: !_vm.isImporting,
                    content: "文件导入中...",
                    effect: "dark",
                    placement: "top-start"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "inline-flex" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.isImporting,
                            type: "primary",
                            loading: _vm.exportLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.saveData("ruleForm")
                            }
                          }
                        },
                        [_vm._v("导 入")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }