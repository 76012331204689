var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "addModelEssay" }, [
      _c(
        "div",
        { staticStyle: { width: "930px" } },
        [
          _c(
            "el-form",
            {
              ref: "compositionInfo",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.compositionInfo,
                "label-width": "80px",
                rules: _vm.rules
              }
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
              _vm._v(" "),
              _vm.type === "edit"
                ? _c("el-form-item", { attrs: { label: "范文ID:" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.compositionInfo.compositionAttr.compositionOpenId
                      )
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "范文标题",
                    prop: "compositionAttr.compositionName"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入范文标题",
                      maxlength: "45",
                      "show-word-limit": ""
                    },
                    model: {
                      value:
                        _vm.compositionInfo.compositionAttr.compositionName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.compositionInfo.compositionAttr,
                          "compositionName",
                          $$v
                        )
                      },
                      expression:
                        "compositionInfo.compositionAttr.compositionName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "范文摘要" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2 },
                      placeholder: "请输入范文摘要",
                      maxlength: "200",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.compositionInfo.compositionAttr.summary,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.compositionInfo.compositionAttr,
                          "summary",
                          $$v
                        )
                      },
                      expression: "compositionInfo.compositionAttr.summary"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "范文正文",
                    prop: "compositionContent.compositionContent"
                  }
                },
                [
                  _c("editor", {
                    model: {
                      value:
                        _vm.compositionInfo.compositionContent
                          .compositionContent,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.compositionInfo.compositionContent,
                          "compositionContent",
                          $$v
                        )
                      },
                      expression:
                        "compositionInfo.compositionContent.compositionContent"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "范文配音" } },
                [
                  _c("upload-audio", {
                    ref: "uploadAudioControl",
                    attrs: {
                      type: "audio",
                      audioId: _vm.compositionInfo.compositionContent.audioUrl,
                      "accept-file-type": "mp3,wma,m4a,wav"
                    },
                    on: { getResult: _vm.getAudio }
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-input", {
                        attrs: {
                          value: _vm.compositionInfo.compositionContent.audioUrl
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "字幕上传" } }, [
                !_vm.compositionInfo.compositionContent.lrc
                  ? _c("div", { staticClass: "file-upload-box" }, [
                      _c("div", { staticClass: "file-upload-bg" }, [
                        _c("div", { staticClass: "file-txt" }, [
                          _vm._v("点击上传")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          ref: "uploadLrc",
                          staticClass: "file-upload",
                          attrs: {
                            disabled: _vm.uploadLrcDisabled,
                            accept: ".lrc,.txt",
                            type: "file"
                          },
                          on: {
                            change: function($event) {
                              return _vm.uploadLrc($event)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "upload-txt-bg" }, [
                        _c("i", {
                          staticClass: "el-icon-info",
                          staticStyle: { color: "#409EFF" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "upload-txt" }, [
                          _vm._v("仅限上传lrc文件，且不超过1M。")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.captionName,
                            expression: "captionName"
                          }
                        ],
                        attrs: { type: "hidden" },
                        domProps: { value: _vm.captionName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.captionName = $event.target.value
                          }
                        }
                      })
                    ])
                  : _c("div", { staticClass: "file-completed-box2" }, [
                      _c("div", { staticClass: "file-txt" }, [
                        _c("i", { staticClass: "iconfont icon-lyric" }),
                        _vm._v(
                          _vm._s(
                            _vm.compositionInfo.compositionContent.captionName
                          )
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "icon-del-lrc",
                            attrs: { disabled: _vm.uploadLrcDisabled },
                            on: { click: _vm.delCaption }
                          },
                          [_vm._v("×")]
                        )
                      ])
                    ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("范文属性")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "体裁", prop: "compositionAttr.typeCodes" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择任意体裁",
                      options: _vm.genreLists,
                      props: _vm.defaultProps,
                      multiple: "",
                      clearable: ""
                    },
                    on: { change: _vm.typeLength },
                    model: {
                      value: _vm.compositionInfo.compositionAttr.typeCodes,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.compositionInfo.compositionAttr,
                          "typeCodes",
                          $$v
                        )
                      },
                      expression: "compositionInfo.compositionAttr.typeCodes"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "同步",
                        prop: "compositionAttr.sectionCode"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "学段" },
                          on: { change: _vm.saveSectionCode },
                          model: {
                            value:
                              _vm.compositionInfo.compositionAttr.sectionCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.compositionInfo.compositionAttr,
                                "sectionCode",
                                $$v
                              )
                            },
                            expression:
                              "compositionInfo.compositionAttr.sectionCode"
                          }
                        },
                        _vm._l(_vm.sectionsList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.subjectCode,
                            callback: function($$v) {
                              _vm.subjectCode = $$v
                            },
                            expression: "subjectCode"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.subjectNames,
                              value: _vm.subjectCode
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "compositionAttr.editionCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "版本" },
                          on: { change: _vm.saveEditionCode },
                          model: {
                            value:
                              _vm.compositionInfo.compositionAttr.editionCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.compositionInfo.compositionAttr,
                                "editionCode",
                                $$v
                              )
                            },
                            expression:
                              "compositionInfo.compositionAttr.editionCode"
                          }
                        },
                        _vm._l(_vm.editionLists, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "compositionAttr.gradeCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "年级" },
                          on: { change: _vm.saveGradeCode },
                          model: {
                            value:
                              _vm.compositionInfo.compositionAttr.gradeCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.compositionInfo.compositionAttr,
                                "gradeCode",
                                $$v
                              )
                            },
                            expression:
                              "compositionInfo.compositionAttr.gradeCode"
                          }
                        },
                        _vm._l(_vm.gradeLists, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "compositionAttr.volumeCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "阶段" },
                          on: { change: _vm.saveVolumeCode },
                          model: {
                            value:
                              _vm.compositionInfo.compositionAttr.volumeCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.compositionInfo.compositionAttr,
                                "volumeCode",
                                $$v
                              )
                            },
                            expression:
                              "compositionInfo.compositionAttr.volumeCode"
                          }
                        },
                        _vm._l(_vm.syncVolumeLists, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "numTag",
                  attrs: { label: "字数", prop: "compositionAttr.countCode" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择字数范围" },
                      model: {
                        value: _vm.compositionInfo.compositionAttr.countCode,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.compositionInfo.compositionAttr,
                            "countCode",
                            $$v
                          )
                        },
                        expression: "compositionInfo.compositionAttr.countCode"
                      }
                    },
                    _vm._l(_vm.countLists, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        staticStyle: { width: "100%" },
                        attrs: { label: item.countName, value: item.countCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "property-box" },
                [
                  _c("el-collapse-transition", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowProperty,
                            expression: "isShowProperty"
                          }
                        ],
                        staticClass: "property-item-bg"
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "单元", prop: "unit" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "rgba(144, 147, 153, 100)"
                                }
                              },
                              [_vm._v("同步范文请选择单元")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticClass: "inputLeft",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择所属单元"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.getUntil($event)
                                  }
                                },
                                model: {
                                  value:
                                    _vm.compositionInfo.compositionAttr
                                      .unitCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.compositionInfo.compositionAttr,
                                      "unitCode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "compositionInfo.compositionAttr.unitCode"
                                }
                              },
                              _vm._l(_vm.untilList, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.unitName,
                                    value: item.unitCode
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "奖项" } },
                          [
                            _c("el-cascader", {
                              ref: "awardList",
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择任意奖项",
                                options: _vm.prizeLists,
                                props: _vm.priceProp,
                                clearable: ""
                              },
                              on: { change: _vm.getPriceCode },
                              model: {
                                value: _vm.awardCode,
                                callback: function($$v) {
                                  _vm.awardCode = $$v
                                },
                                expression: "awardCode"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "专题" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  multiple: "",
                                  placeholder: "请选择专题"
                                },
                                on: { change: _vm.theme },
                                model: {
                                  value:
                                    _vm.compositionInfo.compositionAttr
                                      .themeCodes,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.compositionInfo.compositionAttr,
                                      "themeCodes",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "compositionInfo.compositionAttr.themeCodes"
                                }
                              },
                              _vm._l(_vm.themeList, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.themeName,
                                    value: item.themeCode
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "标签" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  multiple: "",
                                  filterable: "",
                                  "allow-create": "",
                                  "default-first-option": "",
                                  placeholder: "请选择或创建标签"
                                },
                                on: { change: _vm.getTag },
                                model: {
                                  value:
                                    _vm.compositionInfo.compositionAttr.tags,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.compositionInfo.compositionAttr,
                                      "tags",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "compositionInfo.compositionAttr.tags"
                                }
                              },
                              _vm._l(_vm.tags, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: index }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    class: [
                      "property-item-arrow",
                      _vm.isShowProperty
                        ? "el-icon-arrow-up"
                        : "el-icon-arrow-down"
                    ],
                    on: {
                      click: function($event) {
                        _vm.isShowProperty = !_vm.isShowProperty
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("范文作者")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modelAuthor" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "作者" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          maxlength: "10",
                          placeholder: "请填写作者",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.compositionInfo.compositionAttr.authorName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.compositionInfo.compositionAttr,
                              "authorName",
                              $$v
                            )
                          },
                          expression:
                            "compositionInfo.compositionAttr.authorName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v("指导老师")
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          maxlength: "10",
                          placeholder: "请填写指导教师",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.compositionInfo.compositionAttr.guider,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.compositionInfo.compositionAttr,
                              "guider",
                              $$v
                            )
                          },
                          expression: "compositionInfo.compositionAttr.guider"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "地区" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          props: _vm.areaProps,
                          placeholder: "请选择或搜索地区",
                          options: _vm.areaList,
                          clearable: "",
                          filterable: ""
                        },
                        on: { change: _vm.getAreaInfo },
                        model: {
                          value: _vm.areaCodes,
                          callback: function($$v) {
                            _vm.areaCodes = $$v
                          },
                          expression: "areaCodes"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "学校" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: {
                            remote: "",
                            "remote-method": _vm.remoteMethod,
                            clearable: "",
                            disabled: _vm.isShowList,
                            filterable: "",
                            placeholder: _vm.schoolHolder
                          },
                          model: {
                            value:
                              _vm.compositionInfo.compositionAttr.schoolName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.compositionInfo.compositionAttr,
                                "schoolName",
                                $$v
                              )
                            },
                            expression:
                              "compositionInfo.compositionAttr.schoolName"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.schoolLoading,
                                  expression: "schoolLoading"
                                }
                              ],
                              attrs: { "element-loading-text": "加载中" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "infinite-scroll",
                                      rawName: "v-infinite-scroll",
                                      value: _vm.loadScroll,
                                      expression: "loadScroll"
                                    }
                                  ],
                                  staticStyle: {
                                    overflow: "auto",
                                    height: "205px"
                                  },
                                  attrs: {
                                    "infinite-scroll-immediate": "false"
                                  }
                                },
                                _vm._l(_vm.schoolList, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.schoolOpenId
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.getSchoolName(item)
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("范文点评")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "点评人" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: {
                            clearable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            filterable: "",
                            placeholder: "请输入或选择点评人"
                          },
                          model: {
                            value:
                              _vm.compositionInfo.compositionContent.remarker,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.compositionInfo.compositionContent,
                                "remarker",
                                $$v
                              )
                            },
                            expression:
                              "compositionInfo.compositionContent.remarker"
                          }
                        },
                        _vm._l(_vm.commentList.reviewerList, function(
                          item,
                          index
                        ) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头衔" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          "show-word-limit": "",
                          maxlength: "10",
                          placeholder: "请填写点评人头衔"
                        },
                        model: {
                          value:
                            _vm.compositionInfo.compositionContent
                              .remarkerTitle,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.compositionInfo.compositionContent,
                              "remarkerTitle",
                              $$v
                            )
                          },
                          expression:
                            "compositionInfo.compositionContent.remarkerTitle"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "点评内容" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请填写点评内容",
                      maxlength: "200",
                      autosize: { minRows: 2 },
                      "show-word-limit": ""
                    },
                    model: {
                      value:
                        _vm.compositionInfo.compositionContent.remarkContent,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.compositionInfo.compositionContent,
                          "remarkContent",
                          $$v
                        )
                      },
                      expression:
                        "compositionInfo.compositionContent.remarkContent"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("范文设置")]),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticStyle: { "margin-left": "-45px" } },
                  [
                    _c("span", [_vm._v("仅VIP可见")]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66" },
                      model: {
                        value: _vm.compositionInfo.compositionAttr.vipOnly,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.compositionInfo.compositionAttr,
                            "vipOnly",
                            $$v
                          )
                        },
                        expression: "compositionInfo.compositionAttr.vipOnly"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "60px", width: "100%" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "rightBtn" }, [
        _vm.compositionInfo.compositionAttr.pubStatus
          ? _c(
              "div",
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.compositionInfo.compositionAttr.pubTime) +
                    "\n        "
                ),
                _c("el-tag", { attrs: { type: "success" } }, [_vm._v("已发布")])
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.compositionInfo.compositionAttr.pubStatus &&
        !_vm.compositionInfo.compositionAttr.deletedAt
          ? _c(
              "div",
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.compositionInfo.compositionAttr.createdAt) +
                    "\n        "
                ),
                _c("el-tag", [_vm._v("草稿")])
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.compositionInfo.compositionAttr.pubStatus &&
        _vm.compositionInfo.compositionAttr.deletedAt
          ? _c(
              "div",
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.compositionInfo.compositionAttr.deletedAt) +
                    "\n        "
                ),
                _c("el-tag", { attrs: { type: "danger" } }, [_vm._v("已移除")])
              ],
              1
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footerBtn" }, [
      _c("div"),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { plain: "", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.saveForm("compositionInfo", 1)
                }
              }
            },
            [_vm._v("保存至草稿")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "success" },
              on: {
                click: function($event) {
                  return _vm.saveForm("compositionInfo", 2)
                }
              }
            },
            [_vm._v("保存并发布")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }