var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "audio-wrapper"
    },
    [
      _c(
        "div",
        { staticClass: "audio-wrapper-bg" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.singleFileInfo,
                rules: _vm.rules,
                "status-icon": "",
                "label-width": "90px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-audio-wrapper" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          基本信息\n          "),
                    _vm.openId && _vm.singleFileInfo.state
                      ? _c(
                          "div",
                          { staticClass: "state-bg" },
                          [
                            _c("span", { staticClass: "state-date" }, [
                              _vm._v(
                                _vm._s(_vm.statusList[0].dateLabel) +
                                  ":" +
                                  _vm._s(
                                    _vm.getDateDiff(
                                      _vm.singleFileInfo[
                                        _vm.statusList[0].dateName
                                      ]
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "timer",
                                attrs: { effect: "dark", placement: "top" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "line-height": "20px" },
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.statusList[0].dateLabel) +
                                        "：" +
                                        _vm._s(
                                          _vm._f("parseTime")(
                                            _vm.singleFileInfo[
                                              _vm.statusList[0].dateName
                                            ],
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.singleFileInfo.createdAt
                                      ? _c("span", [
                                          _vm._v(
                                            "创建时间：" +
                                              _vm._s(
                                                _vm._f("parseTime")(
                                                  _vm.singleFileInfo.createdAt,
                                                  "{y}-{m}-{d} {h}:{i}"
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tag",
                                  {
                                    staticClass: "state-tag",
                                    attrs: { type: _vm.statusList[0].typeName }
                                  },
                                  [_vm._v(_vm._s(_vm.statusList[0].btnName))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.singleFileInfo.resourceOpenId
                    ? _c("el-form-item", { attrs: { label: "作品ID" } }, [
                        _vm.singleFileInfo.resourceOpenId
                          ? _c("div", { staticClass: "title-id" }, [
                              _vm._v(_vm._s(_vm.singleFileInfo.resourceOpenId))
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "文件名称", prop: "title" } },
                    [
                      _c("el-autocomplete", {
                        staticClass: "input-box",
                        staticStyle: { width: "850px" },
                        attrs: {
                          disabled: _vm.allowEdit,
                          maxlength: 100,
                          debounce: 500,
                          "show-word-limit": "",
                          placeholder: "请填文件名称",
                          "fetch-suggestions": _vm.searchTitle
                        },
                        model: {
                          value: _vm.singleFileInfo.title,
                          callback: function($$v) {
                            _vm.$set(_vm.singleFileInfo, "title", $$v)
                          },
                          expression: "singleFileInfo.title"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作品编号" } },
                    [
                      _c("el-input", {
                        staticClass: "txt-width850",
                        attrs: {
                          disabled: _vm.allowEdit,
                          placeholder: "请输入作品编号",
                          maxlength: "50",
                          "show-word-limit": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.singleFileInfo.resourceEncode,
                          callback: function($$v) {
                            _vm.$set(_vm.singleFileInfo, "resourceEncode", $$v)
                          },
                          expression: "singleFileInfo.resourceEncode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "文件上传", prop: "filePath" } },
                    [
                      _c(
                        "div",
                        { staticClass: "file-upload-wrapper" },
                        [
                          _c("single-file-upload", {
                            staticClass: "file-upload-wrapper-input",
                            attrs: {
                              disabled: _vm.allowEdit,
                              "resource-open-id":
                                _vm.singleFileInfo.resourceOpenId,
                              "resource-title": _vm.singleFileInfo.title
                            },
                            on: {
                              delFile: _vm.delFile,
                              fileUploading: _vm.fileUploading,
                              getResult: _vm.getUploader
                            },
                            model: {
                              value: _vm.fileList,
                              callback: function($$v) {
                                _vm.fileList = $$v
                              },
                              expression: "fileList"
                            }
                          }),
                          _vm._v(" "),
                          _vm.showUpdateIcon
                            ? _c("i", {
                                staticClass: "el-icon-success file-update-icon"
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.singleFileInfo.filePath,
                          callback: function($$v) {
                            _vm.$set(_vm.singleFileInfo, "filePath", $$v)
                          },
                          expression: "singleFileInfo.filePath"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "可预览页数" } }, [
                    _c(
                      "div",
                      { staticClass: "preview" },
                      [
                        _c("el-input-number", {
                          attrs: {
                            disabled: _vm.allowEdit,
                            "controls-position": "right",
                            min: 0
                          },
                          model: {
                            value: _vm.singleFileInfo.previewLength,
                            callback: function($$v) {
                              _vm.$set(_vm.singleFileInfo, "previewLength", $$v)
                            },
                            expression: "singleFileInfo.previewLength"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "preview-checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { disabled: _vm.allowEdit },
                              model: {
                                value: _vm.singleFileInfo.preview,
                                callback: function($$v) {
                                  _vm.$set(_vm.singleFileInfo, "preview", $$v)
                                },
                                expression: "singleFileInfo.preview"
                              }
                            }),
                            _c("span", { staticClass: "preview-margin" }, [
                              _vm._v("未购买不可预览")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "preview-checkbox" }, [
                          _c("i", {
                            staticClass: "el-icon-info",
                            staticStyle: {
                              color: "#409EFF",
                              "margin-right": "5px"
                            }
                          }),
                          _c("span", { staticClass: "preview-txt" }, [
                            _vm._v("默认为0，代表不限制预览页数")
                          ])
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-collapse-transition", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.coverCollapse,
                            expression: "coverCollapse"
                          }
                        ]
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "文件封面", prop: "cover" } },
                          [
                            _c("cover-upload", {
                              attrs: {
                                path: _vm.path,
                                "partner-open-id": _vm.partnerOpenId,
                                coverarr: _vm.coverArr,
                                covertext: _vm.coverText,
                                type: _vm.SINGLE_FILE_ID.toString(),
                                disabledEdit: _vm.allowEdit
                              },
                              on: { deletecover: _vm.deletecoverFun },
                              model: {
                                value: _vm.singleFileInfo.cover,
                                callback: function($$v) {
                                  _vm.$set(_vm.singleFileInfo, "cover", $$v)
                                },
                                expression: "singleFileInfo.cover"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "文件介绍", prop: "detail" } },
                          [
                            _c("editor-view", {
                              ref: "editorView",
                              attrs: { readonly: _vm.allowEdit },
                              model: {
                                value: _vm.singleFileInfo.detail,
                                callback: function($$v) {
                                  _vm.$set(_vm.singleFileInfo, "detail", $$v)
                                },
                                expression: "singleFileInfo.detail"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    class: [
                      "property-item-arrow",
                      _vm.coverCollapse
                        ? "el-icon-arrow-up"
                        : "el-icon-arrow-down"
                    ],
                    on: {
                      click: function($event) {
                        _vm.coverCollapse = !_vm.coverCollapse
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("作品属性")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "同步" } },
                    [
                      _c("sync-view", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.singleFileInfo.tempCode,
                          callback: function($$v) {
                            _vm.$set(_vm.singleFileInfo, "tempCode", $$v)
                          },
                          expression: "singleFileInfo.tempCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "types" } },
                    [
                      _c("works", {
                        ref: "worksView",
                        attrs: { isDisabled: _vm.allowEdit },
                        on: { "add-classtion": _vm.addClass }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        attrs: { value: _vm.singleFileInfo.types }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版次" } },
                    [
                      _c("edition-box", {
                        staticStyle: { width: "850px" },
                        attrs: { isDisabled: _vm.allowEdit },
                        model: {
                          value: _vm.singleFileInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.singleFileInfo, "periodCode", $$v)
                          },
                          expression: "singleFileInfo.periodCode"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.singleFileInfo.periodCode,
                          callback: function($$v) {
                            _vm.$set(_vm.singleFileInfo, "periodCode", $$v)
                          },
                          expression: "singleFileInfo.periodCode"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "类目", prop: "secondRegimentationIds" }
                    },
                    [
                      _c("category-view", {
                        staticStyle: { width: "850px" },
                        attrs: { "is-disabled": _vm.allowEdit },
                        on: { "category-change": _vm.categoryChange },
                        model: {
                          value: _vm.singleFileInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.singleFileInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "singleFileInfo.secondRegimentationIds"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.singleFileInfo.secondRegimentationIds,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.singleFileInfo,
                              "secondRegimentationIds",
                              $$v
                            )
                          },
                          expression: "singleFileInfo.secondRegimentationIds"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "property-box" },
                    [
                      _c("el-collapse-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowProperty,
                                expression: "isShowProperty"
                              }
                            ],
                            staticClass: "property-item-bg"
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "地区" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择地区"
                                    },
                                    model: {
                                      value: _vm.areaValue,
                                      callback: function($$v) {
                                        _vm.areaValue = $$v
                                      },
                                      expression: "areaValue"
                                    }
                                  },
                                  _vm._l(_vm.areaList, function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "标签" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "850px" },
                                    attrs: {
                                      disabled: _vm.allowEdit,
                                      multiple: "",
                                      filterable: "",
                                      "allow-create": "",
                                      "default-first-option": "",
                                      placeholder: "请输入或选择商品标签"
                                    },
                                    on: { change: _vm.changeTags },
                                    model: {
                                      value: _vm.tagValue,
                                      callback: function($$v) {
                                        _vm.tagValue = $$v
                                      },
                                      expression: "tagValue"
                                    }
                                  },
                                  _vm._l(_vm.tagList, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        class: [
                          "property-item-arrow",
                          _vm.isShowProperty
                            ? "el-icon-arrow-up"
                            : "el-icon-arrow-down"
                        ],
                        on: {
                          click: function($event) {
                            _vm.isShowProperty = !_vm.isShowProperty
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("商品设置")]),
                  _vm._v(" "),
                  _c("goods-set", {
                    ref: "commodity",
                    attrs: {
                      partnerOpenId: _vm.partnerOpenId,
                      "independent-sale": _vm.singleFileInfo.independentSale,
                      disabledEdit: _vm.allowEdit,
                      "resource-open-id": _vm.openId,
                      "type-name": _vm.typeName
                    },
                    on: { "official-partner": _vm.getOfficialPartner }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("works-operation-buttton", {
                attrs: {
                  openId: _vm.openId,
                  allowEdit: _vm.allowEdit,
                  operation: _vm.operation,
                  status: _vm.singleFileInfo.state,
                  passBtn: _vm.passBtn,
                  refuseBtn: _vm.refuseBtn,
                  restoreBtn: _vm.restoreBtn,
                  publishBtn: _vm.publishBtn,
                  saveBtn: _vm.saveBtn,
                  typeName: _vm.typeName,
                  saveDraftBtn: _vm.saveDraftBtn
                },
                on: {
                  closeView: _vm.closeView,
                  updateEdit: _vm.updateEdit,
                  passItem: _vm.passItem,
                  handelRefuse: _vm.handelRefuse,
                  handelSaveAndReview: _vm.handelSaveAndReview,
                  handleBookRestore: _vm.handleBookRestore,
                  saveData: _vm.saveData,
                  saveDraft: _vm.saveDraft
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "refuse-book-bg",
          attrs: { visible: _vm.dialogRefuse, title: "驳回申请" },
          on: {
            "update:visible": function($event) {
              _vm.dialogRefuse = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "refuseForm",
              attrs: {
                model: _vm.refuseForm,
                rules: _vm.refuseRules,
                "label-width": "110px",
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "reason" } },
                [
                  _c("el-input", {
                    staticClass: "txt-reason",
                    attrs: {
                      autosize: { minRows: 1, maxRows: 1 },
                      type: "textarea",
                      placeholder: "请简述驳回原因，不超过20个字"
                    },
                    model: {
                      value: _vm.refuseForm.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.refuseForm, "reason", $$v)
                      },
                      expression: "refuseForm.reason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRefuse = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.refuseObj()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }