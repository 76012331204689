"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _dayjs = _interopRequireDefault(require("dayjs"));

var _uploadOSS = require("@/utils/uploadOSS");

var _index = _interopRequireDefault(require("@/components/coverupload/index"));

var _index2 = _interopRequireDefault(require("../../../../../General/File/VideoUploader/index"));

var _index3 = _interopRequireDefault(require("../../../../../General/Image/ImageWall/index"));

var _main = require("../../../../../../organizationLibs/editor/main");

var _index4 = _interopRequireDefault(require("@/components/General/From/Selector/RadioGroup/index"));

var _ResourceTypeRadioGroup = _interopRequireDefault(require("@/components/General/From/Selector/RadioGroup/ResourceTypeRadioGroup"));

var _CardContent = _interopRequireDefault(require("../Base/CardContent"));

var _InputWithButton = _interopRequireDefault(require("./InputWithButton"));

var _ProductNumberContent = _interopRequireDefault(require("./ProductNumberContent"));

var _ProductCombinationContent = _interopRequireDefault(require("./ProductCombinationContent"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CV_Product_Product_EditProduct_EditBaseInfo_index',
  components: {
    CoverUpload: _index.default,
    ImageWall: _index3.default,
    VideoUploader: _index2.default,
    RichTextEditor: _main.Editor,
    CardContent: _CardContent.default,
    InputWithButton: _InputWithButton.default,
    MyRadioGroup: _index4.default,
    ResourceTypeRadioGroup: _ResourceTypeRadioGroup.default,
    EditBaseInfoProductNumberContent: _ProductNumberContent.default,
    EditBaseInfoProductCombinationContent: _ProductCombinationContent.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    clearValidate: {
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    pageType: {
      type: String,
      default: 'CREATE'
    }
  },
  data: function data() {
    return {
      checkData: [{
        label: true,
        title: '是'
      }, {
        label: false,
        title: '否'
      }],
      resourceTypeCheckData: [{
        label: 20,
        title: '实物商品'
      }, {
        label: 21,
        title: '数字商品'
      }, {
        label: 22,
        title: '组合商品'
      }],
      specificationTypeCheckData: [{
        label: 0,
        title: '单规格'
      }, {
        label: 1,
        title: '多规格'
      }],
      path: "cover/".concat((0, _dayjs.default)().format('YYYY-MM-DD'), "/"),
      coverText: '建议尺寸750*750px，JPG、PNG格式， 图片小于5M。',
      coverArr: ['90', '90'],
      atlasArr: '你可以拖拽图片调整图片顺序',
      ossUploadFunction: _uploadOSS.productUploadBatchOSS,
      atlasImagePath: "atlas/".concat((0, _dayjs.default)().format('YYYY-MM-DD'), "/")
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    stIsSingleSpecification: function stIsSingleSpecification() {
      return this.valueProxy.specificType === 0;
    },
    // 规格类型：是否是单规格
    stIsMultipleSpecifications: function stIsMultipleSpecifications() {
      return !this.stIsSingleSpecification;
    },
    // 规格类型：是否是多规格
    showIsSuit: function showIsSuit() {
      return false;
    },
    // 是否显示是否套装
    showSpecificType: function showSpecificType() {
      return this.valueProxy.resourceType === 20;
    },
    // 是否显示规格类型
    showProductId: function showProductId() {
      // 是否显示ERP商品id
      // if (this.valueProxy.resourceType === 20) {
      //   if (this.stIsMultipleSpecifications) { return false; }
      // } else {
      //   return false;
      // }
      // return true;
      return false;
    },
    showSkuCode: function showSkuCode() {
      return false;
    },
    // 是否显示sku编码
    showContentRequestList: function showContentRequestList() {
      return this.valueProxy.resourceType !== 20;
    },
    // 是否显示商品内容
    disabledResourceType: function disabledResourceType() {
      return this.pageType === 'EDIT' || ['e-commodity-list', 'commodity-list', 'g-commodity-list'].includes(this.$route.query.from);
    },
    // 商品类型是否禁止编辑
    showResourceType: function showResourceType() {
      return !['e-commodity-list', 'commodity-list', 'g-commodity-list'].includes(this.$route.query.from);
    }
  },
  watch: {
    'valueProxy.isUseErp': function valueProxyIsUseErp() {
      this.clearValidate();
    },
    'valueProxy.resourceType': function valueProxyResourceType(c, b) {
      if (![21, 22].includes(b)) {
        return;
      }

      this.valueProxy.contentRequestList = [];
    },
    'valueProxy.showVideo': function valueProxyShowVideo(c, b) {
      if (!b) {
        return;
      }

      this.valueProxy.showVideoCover = null;
    }
  },
  methods: {
    deletecoverFun: function deletecoverFun() {
      this.valueProxy.cover = '';
    },
    onChange: function onChange() {
      this.$emit('single-locked-need-update');
    }
  }
};
exports.default = _default2;