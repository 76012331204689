"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _priceParser = require("@taozhi/price-parser");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DownloadResourceSet',
  props: {
    value: {
      type: Object,
      default: function _default() {}
    },
    allowEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        return this.$emit('input', val);
      }
    },
    downloadPrice: {
      get: function get() {
        return (0, _priceParser.priceToYuan)(this.valueProxy.downloadPrice);
      },
      set: function set(val) {
        this.valueProxy.downloadPrice = (0, _priceParser.parsePriceFromYuan)(val);
      }
    }
  }
};
exports.default = _default2;