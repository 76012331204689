var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderDetailWrap" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单信息",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": true,
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            width: "1192px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "el-dialog-div" },
                [
                  _c("div", { staticClass: "info_item" }, [
                    _c(
                      "div",
                      { staticClass: "display_flex align_items space_between" },
                      [
                        _c("div", { staticClass: "display_flex align_items" }, [
                          _c("div", [
                            _c("span", { staticClass: "info_key" }, [
                              _vm._v("订单号：")
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "info_value" }, [
                              _vm._v(
                                _vm._s(_vm.showVal(_vm.orderDetailData.orderId))
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.orderDetailData.orderTags
                            ? _c(
                                "div",
                                { staticClass: "marginLeft_10" },
                                [
                                  _c("el-tag", { attrs: { size: "medium" } }, [
                                    _vm._v(
                                      _vm._s(_vm.orderDetailData.orderTags)
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-link",
                                {
                                  staticClass: "marginLeft_10",
                                  attrs: { underline: false, type: "primary" },
                                  on: { click: _vm.showLogDia }
                                },
                                [_vm._v("订单操作记录")]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "display_flex align_items flex_end" },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm._f("parseTime")(
                                      _vm.showVal(
                                        _vm.orderDetailData.createdAt
                                      ),
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "marginLeft_10",
                                attrs: {
                                  size: "mini",
                                  type: _vm.getOrderStatusBtnType(
                                    _vm.orderDetailData.orderStatusName
                                  ),
                                  disabled: "",
                                  plain: ""
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.orderDetailData.orderStatusName
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.afterSaleNum > 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "danger",
                                      disabled: "",
                                      plain: ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          "存在售后商品（" +
                                            _vm.afterSaleNum +
                                            "）"
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "display_flex align_items",
                        staticStyle: {
                          width: "100%",
                          "margin-top": "10px",
                          "margin-bottom": "20px",
                          padding: "10px",
                          "background-color": "rgba(246, 247, 251, 1)"
                        }
                      },
                      [
                        _c("span", [
                          _vm._v("\n                  供货方式：\n"),
                          _vm._v(" "),
                          _c("span", [
                            _vm.orderDetailData.allowSetDeliveryMethod
                              ? _c("span", [
                                  _vm._v(
                                    "\n                      待定\n                    "
                                  )
                                ])
                              : _c("span", [
                                  _vm.orderDetailData.hasEntityProduct
                                    ? _c("span", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getSupplyModeName(
                                                _vm.orderDetailData.supplyMode
                                              )
                                            )
                                          )
                                        ])
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "\n                        自动发货\n                      "
                                        )
                                      ])
                                ])
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "info_row_margin" },
                      [
                        _c(
                          "div",
                          { staticClass: "info_title display_flex" },
                          [
                            _vm._v(
                              "\n                提货信息\n                "
                            ),
                            _vm.orderDetailData.supplyMode === 2 &&
                            (_vm.orderDetailData.pickup ||
                              _vm.orderDetailData.express) &&
                            _vm.isAfterSale
                              ? _c(
                                  "el-link",
                                  {
                                    staticClass: "marginLeft_10",
                                    attrs: {
                                      underline: false,
                                      type: "primary"
                                    },
                                    on: { click: _vm.showTihuoDialog }
                                  },
                                  [_vm._v("编辑\n                ")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _vm.orderDetailData.deliveryMethod === 1
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "info_row_margin",
                                    attrs: { span: 8 }
                                  },
                                  [
                                    _c("div", [
                                      _c("span", { staticClass: "info_key" }, [
                                        _vm._v("提取方式：")
                                      ]),
                                      _vm._v(" "),
                                      _vm.orderDetailData.deliveryMethod === 1
                                        ? _c(
                                            "span",
                                            { staticClass: "info_value" },
                                            [_vm._v("快递")]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "info_value" },
                                            [_vm._v("--")]
                                          )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDetailData.deliveryMethod === 2
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "info_row_margin",
                                    attrs: { span: 8 }
                                  },
                                  [
                                    _c("div", [
                                      _c("span", { staticClass: "info_key" }, [
                                        _vm._v("提取码：")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "info_value" },
                                        [
                                          _vm.orderDetailData
                                            .cancelAfterVerificationStatus === 2
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showVal(
                                                        _vm.orderDetailData
                                                          .pickUpCode
                                                      )
                                                    )
                                                )
                                              ])
                                            : _c(
                                                "span",
                                                {
                                                  staticStyle: { color: "red" }
                                                },
                                                [_vm._v("待提货")]
                                              )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "info_row_margin",
                                attrs: { span: 8 }
                              },
                              [
                                _c("div", [
                                  _c("span", { staticClass: "info_key" }, [
                                    _vm._v("收货人：")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "info_value" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showVal(
                                            _vm.orderDetailData.addressRecipient
                                          )
                                        )
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "info_row_margin",
                                attrs: { span: 8 }
                              },
                              [
                                _c("div", [
                                  _c("span", { staticClass: "info_key" }, [
                                    _vm._v("联系电话：")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "info_value" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showVal(
                                            _vm.orderDetailData.addressPhone
                                          )
                                        )
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.orderDetailData.deliveryMethod === 2
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "info_row_margin",
                                    attrs: { span: 8 }
                                  },
                                  [
                                    _c("div", [
                                      _c("span", { staticClass: "info_key" }, [
                                        _vm._v("提货时间：")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "info_value" },
                                        [
                                          _vm._v(
                                            "  " +
                                              _vm._s(
                                                _vm.showVal(
                                                  _vm.orderDetailData.pickUpAt
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDetailData.deliveryMethod === 2
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "info_row_margin",
                                    attrs: { span: 8 }
                                  },
                                  [
                                    _c("div", [
                                      _c("span", { staticClass: "info_key" }, [
                                        _vm._v("自提点：")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "info_value" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showVal(
                                                _vm.orderDetailData.pickUpName
                                              )
                                            ) + " "
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.orderDetailData.deliveryMethod === 1
                          ? _c("div", { staticClass: "info_row_margin" }, [
                              _c("span", { staticClass: "info_key" }, [
                                _vm._v("收货地址：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "info_value" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.showVal(
                                        _vm.orderDetailData.addressProvince
                                      )
                                    ) +
                                    "\n                  " +
                                    _vm._s(
                                      _vm.showVal(
                                        _vm.orderDetailData.addressCity
                                      )
                                    ) +
                                    "\n                  " +
                                    _vm._s(
                                      _vm.showVal(
                                        _vm.orderDetailData.addressCounty
                                      )
                                    ) +
                                    "\n                  " +
                                    _vm._s(
                                      _vm.showVal(
                                        _vm.orderDetailData.addressDetail
                                      )
                                    ) +
                                    "\n                "
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "info_row_margin" }, [
                          _c("span", { staticClass: "info_key" }, [
                            _vm._v("买家留言：")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "info_value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.showVal(_vm.orderDetailData.buyUserRemark)
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "info_row_margin" },
                          [
                            _c("span", { staticClass: "info_key" }, [
                              _vm._v("订单备注：")
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "info_value" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showVal(_vm.orderDetailData.remarks)
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.dialogRemarksinformationVisible = true
                                  }
                                }
                              },
                              [_vm._v("编辑\n                ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "info_item" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", [
                              _c("span", { staticClass: "info_key" }, [
                                _vm._v("付款状态：")
                              ]),
                              _vm._v(" "),
                              _vm.orderDetailData.status === 1
                                ? _c("span", { staticClass: "info_value" }, [
                                    _vm._v("待支付")
                                  ])
                                : _vm.orderDetailData.status === 2
                                ? _c("span", { staticClass: "info_value" }, [
                                    _vm._v("支付成功")
                                  ])
                                : _vm.orderDetailData.status === 3
                                ? _c("span", { staticClass: "info_value" }, [
                                    _vm._v("取消支付")
                                  ])
                                : _vm.orderDetailData.status === 4
                                ? _c("span", { staticClass: "info_value" }, [
                                    _vm._v("退款")
                                  ])
                                : _vm.orderDetailData.status === 5
                                ? _c("span", { staticClass: "info_value" }, [
                                    _vm._v("失效")
                                  ])
                                : _c("span", { staticClass: "info_value" }, [
                                    _vm._v("--")
                                  ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", [
                              _c("span", { staticClass: "info_key" }, [
                                _vm._v("支付方式：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "info_value" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.showVal(
                                      _vm.orderDetailData.payChannelName
                                    )
                                  ) +
                                    " (手续费" +
                                    _vm._s(
                                      Number(
                                        _vm.orderDetailData.commissionRate
                                      ) === 0
                                        ? "--"
                                        : _vm
                                            .showVal(
                                              _vm.orderDetailData
                                                .commissionRate * 1000
                                            )
                                            .toFixed(0) + "‰"
                                    ) +
                                    ")\n                  "
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", [
                              _c("span", { staticClass: "info_key" }, [
                                _vm._v("付款时间：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "info_value" }, [
                                _vm._v(
                                  "\n                   " +
                                    _vm._s(
                                      _vm.showVal(_vm.orderDetailData.payedAt)
                                    ) +
                                    "\n                  "
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "info_row_margin",
                              attrs: { span: 8 }
                            },
                            [
                              _c("div", [
                                _c("span", { staticClass: "info_key" }, [
                                  _vm._v("聚合支付订单号：")
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "info_value" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.showVal(
                                          _vm.orderDetailData.mixOrderId
                                        )
                                      )
                                  )
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "info_row_margin",
                              attrs: { span: 8 }
                            },
                            [
                              _c("div", [
                                _c("span", { staticClass: "info_key" }, [
                                  _vm._v("支付渠道订单号：")
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "info_value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.showVal(
                                        _vm.orderDetailData.channelOrderId
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "info_row_margin",
                              attrs: { span: 8 }
                            },
                            [
                              _c("div", [
                                _c("span", { staticClass: "info_key" }, [
                                  _vm._v("微信支付单号：")
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "info_value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.showVal(
                                        _vm.orderDetailData.mergeOrderId
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.orderDetailData.deliveryMethod == 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "info_row_margin ",
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            " info_title display_flex align_items"
                                        },
                                        [_c("span", [_vm._v("快递信息")])]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "info_row_margin",
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data:
                                              _vm.orderDetailData
                                                .orderFormMaterialFlowRelationShipList,
                                            border: "",
                                            "header-cell-style": {
                                              background: "#F6F7FB",
                                              color: "#000000"
                                            }
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "序号",
                                              align: "center",
                                              type: "index",
                                              width: "50"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "快递信息",
                                              width: "240"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      scope.row.expressType ===
                                                      1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "display_flex align_items"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .postCompanyName
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              _c(
                                                                "el-link",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "5px"
                                                                  },
                                                                  attrs: {
                                                                    type:
                                                                      "primary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.showExpressInfo(
                                                                        scope
                                                                          .row
                                                                          .postCompanyName,
                                                                        scope
                                                                          .row
                                                                          .trackingNumber,
                                                                        scope
                                                                          .row
                                                                          .expressPersonPhone
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .trackingNumber
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      scope.row.expressType ===
                                                      2
                                                        ? _c("span", [
                                                            _vm._v("自行配送")
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      scope.row.expressType ===
                                                      3
                                                        ? _c("span", [
                                                            _vm._v("同城配送")
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              256669101
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: { label: "商品" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "display_flex align_items"
                                                        },
                                                        [
                                                          _c(
                                                            "el-row",
                                                            {
                                                              attrs: {
                                                                gutter: 20
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-col",
                                                                {
                                                                  attrs: {
                                                                    span: 6
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "orderInfo_goodsCover_box"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-image",
                                                                        {
                                                                          staticClass:
                                                                            "orderInfo_goodsCover",
                                                                          attrs: {
                                                                            fit:
                                                                              "cover",
                                                                            src: _vm.getImgUrl(
                                                                              scope
                                                                                .row
                                                                                .cover,
                                                                              _vm.smallthumbnailStyle64
                                                                            )
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "image-slot",
                                                                              attrs: {
                                                                                slot:
                                                                                  "error"
                                                                              },
                                                                              slot:
                                                                                "error"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "orderInfo_goodsCover",
                                                                                  attrs: {
                                                                                    src: require("@/assets/book_cover.png")
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "marginLeft_10"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .title
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              4210267689
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              width: "80",
                                              prop: "deliverQuantity",
                                              align: "center",
                                              label: "数量"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "deliveryStash",
                                              align: "center",
                                              label: "发货仓"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "remarks",
                                              label: "备注"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.showVal(
                                                              scope.row.remarks
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              30222869
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.intraCityList.length > 0
                                    ? _c(
                                        "el-col",
                                        {
                                          staticClass: "info_row_margin ",
                                          attrs: { span: 24 }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                " info_title display_flex align_items"
                                            },
                                            [_c("span", [_vm._v("同城订单")])]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.intraCityList.length > 0
                                    ? _c(
                                        "el-col",
                                        {
                                          staticClass: "info_row_margin ",
                                          attrs: { span: 24 }
                                        },
                                        [
                                          _c(
                                            "el-table",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                border: "",
                                                "header-cell-style": {
                                                  background: "#F6F7FB",
                                                  color: "#000000"
                                                },
                                                data: _vm.intraCityList
                                              }
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  width: "50",
                                                  align: "center",
                                                  type: "index",
                                                  label: "序号"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: { label: "快递信息" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getServiceTransName(
                                                                  scope.row
                                                                    .service_trans_id
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  259385984
                                                )
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: { label: "配送员信息" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          scope.row
                                                            .transporter_info
                                                            ? _c("div", [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.getIntraCityStatus(
                                                                          scope
                                                                            .row
                                                                            .order_status
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .transporter_info
                                                                          .transporter_name
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .transporter_info
                                                                          .transporter_phone
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ])
                                                              ])
                                                            : _c("div", [
                                                                _vm._v("--")
                                                              ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  360891682
                                                )
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "配送费及取货码"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                          配送费：" +
                                                                _vm._s(
                                                                  _vm.showVal(
                                                                    scope.row
                                                                      .actualfee
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                          取货码：" +
                                                                _vm._s(
                                                                  _vm.showVal(
                                                                    scope.row
                                                                      .fetch_code
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2254928819
                                                )
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  width: "300",
                                                  label: "商品"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          scope.row.cargo_info
                                                            ? _c(
                                                                "div",
                                                                _vm._l(
                                                                  scope.row
                                                                    .cargo_info
                                                                    .item_list,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: index
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              item.item_name
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  247363270
                                                )
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "数量",
                                                  width: "50"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          scope.row.cargo_info
                                                            ? _c(
                                                                "div",
                                                                _vm._l(
                                                                  scope.row
                                                                    .cargo_info
                                                                    .item_list,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: index
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            x" +
                                                                            _vm._s(
                                                                              item.count
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1298260336
                                                )
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: { label: "备注" }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "info_item" },
                    [
                      _c("div", { staticClass: "info_title" }, [
                        _vm._v("订单来源")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "info_row_margin",
                              attrs: { span: 24 }
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _c("span", { staticClass: "info_key" }, [
                                  _vm._v("购买渠道：")
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "info_value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.showChannel(
                                        _vm.orderDetailData.channel
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "display_flex align_items justify_content_between"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "info_row_margin",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "display_flex align_items" },
                                [
                                  _c("span", { staticClass: "info_key" }, [
                                    _vm._v("购买用户：")
                                  ]),
                                  _vm._v(" "),
                                  _vm.orderDetailData.buyUserInfo.nickName
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "display_flex align_items"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "userCover_box" },
                                            [
                                              _c(
                                                "el-image",
                                                {
                                                  staticClass: "userCover_box",
                                                  attrs: {
                                                    fit: "cover",
                                                    src: _vm.getImgUrl(
                                                      _vm.orderDetailData
                                                        .buyUserInfo.cover,
                                                      _vm.smallthumbnailStyle
                                                    )
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: { slot: "error" },
                                                      slot: "error"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "userCover_box",
                                                        attrs: {
                                                          src: require("@/assets/book_cover.png")
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "7px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderDetailData
                                                    .buyUserInfo.nickName
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c("div", [_vm._v("--")])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "info_row_margin",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "display_flex align_items" },
                                [
                                  _c("span", { staticClass: "info_key" }, [
                                    _vm._v(
                                      "用户ID：" +
                                        _vm._s(
                                          _vm.orderDetailData.buyUserInfo.openId
                                        )
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "info_row_margin",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "display_flex align_items" },
                                [
                                  _c("span", { staticClass: "info_key" }, [
                                    _vm._v(
                                      "联系方式：" +
                                        _vm._s(
                                          _vm.orderDetailData.buyUserInfo.phone
                                        )
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "display_flex align_items justify_content_between"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "info_row_margin",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "display_flex align_items" },
                                [
                                  _c("span", { staticClass: "info_key" }, [
                                    _vm._v("所属中盘：")
                                  ]),
                                  _vm._v(" "),
                                  _vm.orderDetailData.centralName
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "display_flex align_items"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "userCover_box" },
                                            [
                                              _c(
                                                "el-image",
                                                {
                                                  staticClass: "userCover_box",
                                                  attrs: {
                                                    fit: "cover",
                                                    src: _vm.getImgUrl(
                                                      _vm.orderDetailData
                                                        .centralLogo,
                                                      _vm.smallthumbnailStyle
                                                    )
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: { slot: "error" },
                                                      slot: "error"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "userCover_box",
                                                        attrs: {
                                                          src: require("@/assets/book_cover.png")
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "7px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderDetailData
                                                    .centralName
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c("div", [_vm._v("--")])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "info_row_margin",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "display_flex align_items" },
                                [
                                  _c("span", { staticClass: "info_key" }, [
                                    _vm._v("所属店铺：")
                                  ]),
                                  _vm._v(" "),
                                  _vm.orderDetailData.partnerName
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "display_flex align_items"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "userCover_box" },
                                            [
                                              _c(
                                                "el-image",
                                                {
                                                  staticClass: "userCover_box",
                                                  attrs: {
                                                    fit: "cover",
                                                    src: _vm.getImgUrl(
                                                      _vm.orderDetailData
                                                        .partnerLogo,
                                                      _vm.smallthumbnailStyle
                                                    )
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: { slot: "error" },
                                                      slot: "error"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "userCover_box",
                                                        attrs: {
                                                          src: require("@/assets/book_cover.png")
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "7px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderDetailData
                                                    .partnerName
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c("div", [_vm._v("--")])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "info_row_margin",
                            staticStyle: { width: "100%" }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "info_item" },
                    [
                      _c("div", { staticClass: "info_title" }, [
                        _vm._v("商品信息")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticClass: "info_row_margin",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.orderDetailData.orderFormDetails,
                            "header-cell-style": {
                              background: "#F6F7FB",
                              color: "#000000"
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "openId", prop: "productOpenId" }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "address", label: "商品" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "display_flex align_items"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "goodsCover_box"
                                                },
                                                [
                                                  _c(
                                                    "el-image",
                                                    {
                                                      staticClass: "goodsCover",
                                                      attrs: {
                                                        fit: "cover",
                                                        src: _vm.getImgUrl(
                                                          scope.row.cover,
                                                          _vm.smallthumbnailStyle64
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "image-slot",
                                                          attrs: {
                                                            slot: "error"
                                                          },
                                                          slot: "error"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "goodsCover",
                                                            attrs: {
                                                              src: require("@/assets/book_cover.png")
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  scope.row.commodityType === 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "goodsTag goodsTag_bg_1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            实物\n                          "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  scope.row.commodityType === 2
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "goodsTag goodsTag_bg_2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            数字\n                          "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  scope.row.commodityType === 3
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "goodsTag goodsTag_bg_3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            组合\n                          "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-link",
                                                {
                                                  staticClass: "marginLeft_10",
                                                  attrs: {
                                                    type:
                                                      scope.row
                                                        .commodityType === 2 ||
                                                      scope.row
                                                        .commodityType === 3
                                                        ? "primary"
                                                        : "",
                                                    underline:
                                                      scope.row
                                                        .commodityType === 2 ||
                                                      scope.row
                                                        .commodityType === 3
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showMultipleGoodsDetail(
                                                        scope.row
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.title)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "specification",
                              width: "100",
                              align: "center",
                              label: "规格"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showVal(scope.row.specification)
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "commodityLibrary",
                              width: "100",
                              align: "center",
                              label: "所属商品库"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "buyQuantity",
                              width: "80",
                              align: "center",
                              label: "数量"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showVal(scope.row.buyQuantity)
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              width: "100",
                              align: "center",
                              label: "价格"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "¥" +
                                          _vm._s(
                                            _vm.showVal(
                                              Number(
                                                scope.row.productPrice / 100
                                              ).toFixed(2)
                                            )
                                          )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              width: "100",
                              align: "center",
                              label: "商品优惠"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "display_flex align_items justify_content_center"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showVal(
                                                Number(
                                                  scope.row.reduceResult / 100
                                                ).toFixed(2)
                                              )
                                            ) + " "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        scope.row.orderSnapshot.preferentialType
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: _vm.getOrderSnapshotContent(
                                                    scope.row.orderSnapshot
                                                  ),
                                                  placement: "top"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "margin_left_5",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "14px",
                                                        height: "14px"
                                                      },
                                                      attrs: {
                                                        src: require("@/assets/ic_tip.png")
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              width: "100",
                              align: "center",
                              label: "应付"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showVal(
                                            Number(
                                              scope.row.payPrice / 100
                                            ).toFixed(2)
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "",
                              align: "center",
                              width: "120",
                              label: "售后状态"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.afterSalestatus === 2
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function($event) {
                                                return _vm.jumpInfo(
                                                  scope.row.refundId
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("待审核")]
                                        )
                                      : scope.row.afterSalestatus === 3
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.jumpInfo(
                                                  scope.row.refundId
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("待收货")]
                                        )
                                      : scope.row.afterSalestatus === 4
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.jumpInfo(
                                                  scope.row.refundId
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("待处理")]
                                        )
                                      : scope.row.afterSalestatus === 5
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.jumpInfo(
                                                  scope.row.refundId
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("已完成")]
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            "\n                    --\n                  "
                                          )
                                        ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "address",
                              align: "center",
                              width: "70",
                              label: "操作"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          underline: false,
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showGoodsDialog(
                                              scope.row.productOpenId
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("详情\n                  ")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _vm.scoreList.length > 0
                    ? _c("order-score-table", {
                        staticClass: "info_item",
                        attrs: { data: _vm.scoreList }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDetailData.orderIncomeStatisticList &&
                  _vm.orderDetailData.orderIncomeStatisticList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "info_item" },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: ".info_row_margin",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data:
                                  _vm.orderDetailData.orderIncomeStatisticList,
                                border: "",
                                "header-cell-style": {
                                  background: "#F6F7FB",
                                  color: "#000000"
                                }
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "incomeType",
                                  width: "200px",
                                  label: "收入类型"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "partnerName",
                                  width: "200px",
                                  label: "商户"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "incomeFormula", label: "明细" }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "金额", width: "200px" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            "\n                  ￥" +
                                              _vm._s(scope.row.amount) +
                                              "\n                "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1803840596
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "dialog_foot" }, [
                    _c(
                      "div",
                      { staticClass: "display_flex align_items" },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.getOrderDetail()
                              }
                            }
                          },
                          [_vm._v("刷新")]
                        ),
                        _vm._v(" "),
                        _vm.orderDetailData.deliveryMethod === 2 &&
                        (_vm.orderDetailData.status === 2 ||
                          _vm.orderDetailData.status === 6)
                          ? _c(
                              "el-button",
                              {
                                staticClass: "refund",
                                attrs: { type: "danger", plain: "" },
                                on: { click: _vm.openDialogRefund }
                              },
                              [_vm._v("\n                退款\n              ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orderDetailData.status === 4
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  color: "#606266",
                                  "margin-left": "10px"
                                }
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "退款原因：" +
                                      _vm._s(
                                        _vm.showVal(
                                          _vm.orderDetailData.refundReason
                                        )
                                      )
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "display_flex align_items" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "rgba(48, 49, 51, 1)",
                            "font-size": "20px"
                          }
                        },
                        [
                          _vm._v(
                            "\n                订单金额(元)\n              "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "rgba(48, 49, 51, 1)",
                            "font-size": "20px",
                            "margin-left": "17px"
                          }
                        },
                        [
                          _vm._v(
                            "\n                ¥" +
                              _vm._s(
                                _vm.showVal(
                                  Number(
                                    _vm.orderDetailData.payPrice / 100
                                  ).toFixed(2)
                                )
                              ) +
                              "\n              "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingdialogFormVisible,
              expression: "loadingdialogFormVisible"
            }
          ],
          attrs: {
            "append-to-body": true,
            title: "订单备注",
            visible: _vm.dialogRemarksinformationVisible,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogRemarksinformationVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "orderRecord",
              staticStyle: { padding: "0 20px" },
              attrs: { model: _vm.form }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "orderReason",
                    rules: [{ required: true, message: "订单备注不能为空" }]
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请填写订单备注信息"
                    },
                    model: {
                      value: _vm.form.orderReason,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "orderReason",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.orderReason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRemarksinformationVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.remarksinfo("orderRecord")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "listOfGiftsInfo" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": true,
                id: "dialogBodyeRfund",
                visible: _vm.dialogFormVisibleRefund,
                title: "订单退款",
                width: "610px",
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "before-close": _vm.dismissRefundDialog
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisibleRefund = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleFormRefund",
                  staticClass: "demo-ruleForm",
                  staticStyle: { padding: "20px" },
                  attrs: { model: _vm.ruleFormRefund, "status-icon": "" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "flex" },
                      attrs: {
                        label: "退款原因",
                        prop: "reason",
                        rules: { required: true, message: "请输入退款原因" }
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "480px" },
                        attrs: {
                          type: "textarea",
                          placeholder: "请简要描述退款原因"
                        },
                        model: {
                          value: _vm.ruleFormRefund.reason,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleFormRefund, "reason", $$v)
                          },
                          expression: "ruleFormRefund.reason"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.refundSubmitLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitFormRefund()
                        }
                      }
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.dismissRefundDialog } }, [
                    _vm._v("取 消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("real-goods-info", {
        attrs: { visible: _vm.goodsDialogVisible, "goods-id": _vm.goodsId },
        on: {
          "update:visible": function($event) {
            _vm.goodsDialogVisible = $event
          },
          "update:goodsId": function($event) {
            _vm.goodsId = $event
          },
          "update:goods-id": function($event) {
            _vm.goodsId = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pickUpLoading,
              expression: "pickUpLoading"
            }
          ],
          attrs: {
            title: "编辑提货信息",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.tihuoDialogVisible,
            "append-to-body": true,
            width: "30%",
            "before-close": _vm.disMissDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.tihuoDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "background-color": "#F5F7FA", padding: "10px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "background-color": "white",
                    "border-radius": "4px",
                    padding: "15px"
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "pickupForm",
                      attrs: {
                        model: _vm.pickUpForm,
                        "label-width": "100px",
                        rules:
                          _vm.pickUpForm.deliveryMethod === "1"
                            ? _vm.rules_type1
                            : _vm.rules_type2
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "提取方式", prop: "deliveryMethod" }
                        },
                        [
                          _vm.orderDetailData.express
                            ? _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.pickUpForm.deliveryMethod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.pickUpForm,
                                        "deliveryMethod",
                                        $$v
                                      )
                                    },
                                    expression: "pickUpForm.deliveryMethod"
                                  }
                                },
                                [_vm._v("快递")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.orderDetailData.pickup
                            ? _c(
                                "el-radio",
                                {
                                  attrs: { label: "2" },
                                  model: {
                                    value: _vm.pickUpForm.deliveryMethod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.pickUpForm,
                                        "deliveryMethod",
                                        $$v
                                      )
                                    },
                                    expression: "pickUpForm.deliveryMethod"
                                  }
                                },
                                [_vm._v("自提\n              ")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "addressPhone" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.pickUpForm.addressPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.pickUpForm, "addressPhone", $$v)
                              },
                              expression: "pickUpForm.addressPhone"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "收件人", prop: "addressRecipient" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.pickUpForm.addressRecipient,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.pickUpForm,
                                  "addressRecipient",
                                  $$v
                                )
                              },
                              expression: "pickUpForm.addressRecipient"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.pickUpForm.deliveryMethod === "1" &&
                      _vm.orderDetailData.express
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收货地址",
                                prop: "cityCodeArray"
                              }
                            },
                            [
                              _c("el-cascader", {
                                key: _vm.isResouceShow,
                                staticClass: "input_width",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择所属省市",
                                  props: _vm.props,
                                  options: _vm.options
                                },
                                model: {
                                  value: _vm.pickUpForm.cityCodeArray,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.pickUpForm,
                                      "cityCodeArray",
                                      $$v
                                    )
                                  },
                                  expression: "pickUpForm.cityCodeArray"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pickUpForm.deliveryMethod === "1" &&
                      _vm.orderDetailData.express
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "详细地址",
                                prop: "addressDetail"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea" },
                                model: {
                                  value: _vm.pickUpForm.addressDetail,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.pickUpForm,
                                      "addressDetail",
                                      $$v
                                    )
                                  },
                                  expression: "pickUpForm.addressDetail"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pickUpForm.deliveryMethod === "2" &&
                      _vm.orderDetailData.pickup
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "自提点店铺", prop: "shopOpenId" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  model: {
                                    value: _vm.pickUpForm.shopOpenId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.pickUpForm,
                                        "shopOpenId",
                                        $$v
                                      )
                                    },
                                    expression: "pickUpForm.shopOpenId"
                                  }
                                },
                                _vm._l(_vm.shopOption, function(item) {
                                  return _c("el-option", {
                                    key: item.shopOpenId,
                                    attrs: {
                                      label: item.name,
                                      value: item.shopOpenId
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.disMissDialog } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitPickUp } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作日志",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.logDialogVisible,
            "append-to-body": true,
            width: "45%",
            "before-close": _vm.logHandleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.logDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.logLoading,
                  expression: "logLoading"
                }
              ],
              staticStyle: { "background-color": "#F5F7FA", padding: "10px" }
            },
            [
              _c(
                "div",
                { staticStyle: { "background-color": "#FFFFFF" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { data: _vm.logList, height: "500" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "updateTime",
                          label: "时间",
                          width: "180"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "用户", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "display_flex align_items" },
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticClass: "logUserCover",
                                        attrs: {
                                          fit: "cover",
                                          src: _vm.getImgUrl(
                                            scope.row.cover,
                                            _vm.smallthumbnailStyle
                                          )
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "error" },
                                            slot: "error"
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "logUserCover",
                                              attrs: {
                                                src: require("@/assets/book_cover.png")
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "marginLeft_10" },
                                      [_vm._v(_vm._s(scope.row.displayName))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "logType", label: "内容" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "display_flex align_items flex_end",
                      staticStyle: { padding: "10px" }
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.logParams.page,
                          "page-sizes": [10, 20, 30, 50],
                          "page-size": _vm.logParams.size,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.logTotal
                        },
                        on: {
                          "size-change": _vm.logHandleSizeChange,
                          "current-change": _vm.logHandleCurrentChange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("query-express-info-dialog", {
        attrs: {
          visible: _vm.queryExpressInfoDialogVisible,
          "before-close": _vm.dismissExpressInfo,
          "express-code": _vm.expressCode,
          "express-name": _vm.expressName,
          "express-phone": _vm.expressPhone
        },
        on: {
          "update:visible": function($event) {
            _vm.queryExpressInfoDialogVisible = $event
          }
        }
      }),
      _vm._v(" "),
      _c("product-order-express-setting", {
        attrs: {
          visible: _vm.expressDialog,
          "dis-miss-express-dialog": _vm.disMissExpressDialog,
          "goods-list": _vm.orderDetailData.orderFormDetailEntityContentList,
          "express-list":
            _vm.orderDetailData.orderFormMaterialFlowRelationShipTotalList,
          "order-id": _vm.orderId
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品核销",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.verificationVisible,
            "append-to-body": true,
            width: "500px",
            "before-close": _vm.disMissVerificationDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.verificationVisible = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { padding: "30px" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入核销码", clearable: "" }
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "primary" }
                  },
                  [_vm._v("核销")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.multipleGoodsDetailDialog
        ? _c("multiple-goods-detail", {
            attrs: {
              "goods-list": _vm.multipleGoodsList,
              visible: _vm.multipleGoodsDetailDialog
            },
            on: {
              "update:visible": function($event) {
                _vm.multipleGoodsDetailDialog = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }