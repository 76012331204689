var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("product-list", {
    ref: "list",
    attrs: {
      loading: _vm.loading,
      query: _vm.query,
      data: _vm.data,
      total: _vm.total,
      "content-height": "calc(100vh - 244px)",
      select: _vm.select,
      "select-type": _vm.selectType,
      "show-resource-type": _vm.showResourceType
    },
    on: {
      "update:query": function($event) {
        _vm.query = $event
      },
      "update:select": function($event) {
        _vm.select = $event
      },
      "update:selectType": function($event) {
        _vm.selectType = $event
      },
      "update:select-type": function($event) {
        _vm.selectType = $event
      },
      "update-data": _vm.updateData
    },
    scopedSlots: _vm._u([
      {
        key: "content-action",
        fn: function() {
          return [
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                align: "center",
                width: "60",
                label: "操作"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "actions" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-edit",
                              attrs: {
                                type: "text",
                                disabled: _vm.isActionDisabled(
                                  scope.row.productOpenId
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toRecover(scope.row.productOpenId)
                                }
                              }
                            },
                            [_vm._v("恢复")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "footer-left",
        fn: function() {
          return [
            _c("el-checkbox", {
              staticClass: "pagination-check",
              attrs: { indeterminate: _vm.selectType === "HALF" },
              model: {
                value: _vm.selectedAll,
                callback: function($$v) {
                  _vm.selectedAll = $$v
                },
                expression: "selectedAll"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.toBulkRecover } },
              [_vm._v("批量恢复")]
            ),
            _vm._v(" "),
            _c("el-divider", { attrs: { direction: "vertical" } }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "danger", plain: "" },
                on: { click: _vm.exitRecycleBin }
              },
              [_vm._v("退出回收站")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }