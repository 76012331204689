var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.orderList, height: "100%" }
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              width: "175",
              prop: "orderId",
              label: "订单编号"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "",
              width: "175",
              prop: "mergeOrderId",
              label: "微信支付单号"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", label: "订单金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "¥" +
                          _vm._s(
                            _vm.showVal(
                              Number(scope.row.payPrice / 100).toFixed(2)
                            )
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "150", label: "支付方式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.showPayType(scope.row.payType)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "100", align: "center", label: "付款状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      scope.row.status === 1
                        ? _c("span", [_vm._v("未支付")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.status === 2
                        ? _c("span", [_vm._v("已支付")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.status === 3
                        ? _c("span", [_vm._v("取消支付")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.status === 4
                        ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                            _vm._v("已退款")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.status === 5
                        ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                            _vm._v("已失效")
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "100", align: "center", label: "发货方式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.hasEntityProduct
                      ? _c("span", [
                          scope.row.deliveryMethod === 1
                            ? _c("span", [_vm._v("快递")])
                            : scope.row.deliveryMethod === 2
                            ? _c("span", [_vm._v("自提")])
                            : _c("span", [_vm._v("--")])
                        ])
                      : _c("span", [_c("span", [_vm._v("线上交付")])])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "100", align: "center", label: "物流状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.deliveryMethod === 2
                      ? _c("span", [_vm._v("\n            --\n          ")])
                      : _c("span", [
                          scope.row.materialFlowstatus === 1
                            ? _c("span", [_vm._v("未发货")])
                            : scope.row.materialFlowstatus === 2
                            ? _c("span", [_vm._v("已发货")])
                            : scope.row.materialFlowstatus === 3
                            ? _c("span", [_vm._v("已收货")])
                            : _c("span", [_vm._v("--")])
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "100",
              align: "center",
              prop: "afterSaleType",
              label: "售后状态"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "285", label: "商品" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-row",
                      _vm._l(scope.row.orderFormDetails, function(item, index) {
                        return _c(
                          "el-col",
                          {
                            attrs: {
                              span:
                                scope.row.orderFormDetails.length == 1 ? 24 : 6
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "display_flex align_items" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "goodsCover_box" },
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticClass: "goodsCover",
                                        attrs: {
                                          fit: "cover",
                                          src: _vm.getImgUrl(
                                            item.cover,
                                            _vm.smallthumbnailStyle64
                                          )
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "error" },
                                            slot: "error"
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "goodsCover",
                                              attrs: {
                                                src: require("@/assets/book_cover.png")
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    item.buyQuantity && item.buyQuantity > 1
                                      ? _c(
                                          "div",
                                          { staticClass: "goodsCover_count" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.buyQuantity) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                scope.row.orderFormDetails.length == 1
                                  ? _c(
                                      "span",
                                      { staticClass: "marginLeft_10" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(item.title) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "100",
              align: "center",
              prop: "supplyMode",
              label: "供货方式"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.allowSetDeliveryMethod
                      ? _c("span", [_vm._v("\n            待定\n          ")])
                      : _c("span", [
                          scope.row.hasEntityProduct
                            ? _c("span", [
                                _c(
                                  "span",
                                  {
                                    style: _vm.getSupplyColor(
                                      scope.row.supplyMode
                                    )
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getSupplyModeName(
                                          scope.row.supplyMode
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            : _c("span", [
                                _vm._v("\n              自动发货\n            ")
                              ])
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", align: "center", label: "自提点" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.hasEntityProduct && scope.row.deliveryMethod === 2
                      ? _c("div", [
                          _c("div", [_vm._v(_vm._s(scope.row.pickUpName))])
                        ])
                      : _c("div", [_vm._v("\n            --\n          ")])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "100",
              align: "center",
              prop: "categoryNum",
              label: "品类数"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "100",
              align: "center",
              prop: "productNum",
              label: "商品总数"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", label: "推广员" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(_vm.getPromoterInfo(scope.row)))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", label: "上级推广员" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.getParentPromoterInfo(scope.row)))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "200", align: "center", label: "所属中盘" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.centralName
                      ? _c("div", { staticClass: "display_flex align_items" }, [
                          _c(
                            "div",
                            { staticClass: "userCover_box" },
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "userCover_box",
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      scope.row.centralLogo,
                                      _vm.smallthumbnailStyle
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "userCover_box",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "margin-left": "7px" } }, [
                            _vm._v(_vm._s(scope.row.centralName))
                          ])
                        ])
                      : _c("div", [_vm._v("--")])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "200", align: "center", label: "所属商户" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.partnerName
                      ? _c("div", { staticClass: "display_flex align_items" }, [
                          _c(
                            "div",
                            { staticClass: "userCover_box" },
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "userCover_box",
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      scope.row.partnerLogo,
                                      _vm.smallthumbnailStyle
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "userCover_box",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "margin-left": "7px" } }, [
                            _vm._v(_vm._s(scope.row.partnerName))
                          ])
                        ])
                      : _c("div", [_vm._v("--")])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "200", align: "center", label: "买家" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.buyUserInfo.nickName
                      ? _c("div", { staticClass: "display_flex align_items" }, [
                          _c(
                            "div",
                            { staticClass: "userCover_box" },
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "userCover_box",
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      scope.row.buyUserInfo.cover,
                                      _vm.smallthumbnailStyle
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "userCover_box",
                                        attrs: {
                                          src: require("@/assets/book_cover.png")
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "margin-left": "7px" } }, [
                            _vm._v(_vm._s(scope.row.buyUserInfo.nickName))
                          ])
                        ])
                      : _c("div", [_vm._v("--")])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "100", align: "center", label: "物流公司" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.showVal(scope.row.postCompanyName)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "150", align: "center", label: "快递单号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.showVal(scope.row.trackingNumber)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "150",
              align: "center",
              prop: "detailDeliveryStash",
              label: "发货仓"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "250", align: "center", label: "买家留言" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.showVal(scope.row.buyUserRemark)) +
                          "\n          "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "250", align: "center", label: "订单备注" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.showVal(scope.row.remarks)) +
                          "\n          "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "200", align: "center", label: "购买渠道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.showChannel(scope.row.channel)) +
                          "\n          "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "200", align: "center", label: "付款时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.showVal(scope.row.payedAt)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "200", align: "center", label: "下单时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.showVal(scope.row.createdAt)) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "200", align: "center", label: "渠道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.sceneEntrySourceChannelName) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "140", align: "center", label: "标签" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      [
                        scope.row.orderTags
                          ? _c("el-tag", { attrs: { size: "medium" } }, [
                              _vm._v(_vm._s(scope.row.orderTags))
                            ])
                          : _c("span", [_vm._v("--")])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              width: "80",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-edit",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.toOrderdetail(scope.row.orderId)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.orderDetailDia
        ? _c("product-order-detail", {
            attrs: { visible: _vm.orderDetailDia, orderId: _vm.orderDetailId },
            on: {
              "update:visible": function($event) {
                _vm.orderDetailDia = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }