var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-layout" },
    [
      _c(
        "el-card",
        { staticClass: "view-layout-card" },
        [
          _vm.pageType !== "RECYCLE_BIN"
            ? _c("view-product-list", {
                attrs: {
                  "only-read": _vm.onlyRead,
                  "show-resource-type": false
                },
                on: { "update-page-type": _vm.updatePageType }
              })
            : _c("view-product-list-recycle-bin", {
                attrs: { "show-resource-type": false },
                on: { "update-page-type": _vm.updatePageType }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }